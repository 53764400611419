import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from "../views/HomeView.vue";
import ProductView from "../views/ProductView.vue"
import ProductInfoView from '../views/info/ProductInfoView.vue';
import ExampleView from '../views/ExampleView.vue'
import ExampleInfoView from '../views/info/ExampleInfoView.vue';
import ResultView from '../views/ResultView.vue';
import ResultInfoView from '../views/info/ResultInfoView.vue';
import server from '../views/server.vue';
import DownloadView from '../views/DownloadView.vue';
import AboutUsView from '../views/AboutUsView.vue';
import ContactUsView from '../views/ContactUsView.vue';
import SearchView from '../views/SearchView.vue';
import NewsView from '../views/NewsView.vue';
import NewsInfoView from '../views/info/NewsInfoView.vue';
import SelfService from '../views/SelfService.vue';
import Feedback from '../views/Feedback.vue';
import StudyView from '../views/StudyView.vue';
import pdf from '../views/info/pdf.vue';
import SingleView from '../views/SingleView.vue';
const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/ProductView',
		name: 'ProductView',
		component: ProductView
	},
	{
		path: '/ProductInfoView',
		name: 'ProductInfoView',
		component: ProductInfoView
	},
	{
		path: '/ExampleView',
		name: 'ExampleView',
		component: ExampleView
	},
	{
		path: '/ExampleInfoView',
		name: 'ExampleInfoView',
		component: ExampleInfoView
	},
	{
		path: '/ResultView',
		name: 'ResultView',
		component: ResultView
	},
	{
		path: '/ResultInfoView',
		name: 'ResultInfoView',
		component: ResultInfoView
	},
	{
		path: '/server',
		name: 'server',
		component: server
	},
	{
		path: '/DownloadView',
		name: 'DownloadView',
		component: DownloadView
	},
	{
		path: '/AboutUsView',
		name: 'AboutUsView',
		component: AboutUsView
	},
	{
		path: '/ContactUsView',
		name: 'ContactUsView',
		component: ContactUsView
	},
	{
		path: '/SearchView',
		name: 'SearchView',
		component: SearchView
	},
	{
		path: '/NewsView',
		name: 'NewsView',
		component: NewsView
	},
	{
		path: '/NewsInfoView',
		name: 'NewsInfoView',
		component: NewsInfoView
	},
	{
		path: '/SelfService',
		name: 'SelfService',
		component: SelfService
	},
	{
		path: '/Feedback',
		name: 'Feedback',
		component: Feedback
	},
	{
		path: '/StudyView',
		name: 'StudyView',
		component: StudyView
	},
	{
		path: "/PDF",
		name: "PDF",
		component: pdf,
	},
	{
		path: "/SingleView",
		name: "SingleView",
		component: SingleView,
	},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})
router.beforeEach((to, from, next)=> {

	// 在当前路由改变，但是该组件被复用时调用

	// 举例来说，对于一个带有动态参数的路径 /foo/:id，在 /foo/1 和 /foo/2 之间跳转的时候，

	// 由于会渲染同样的 Foo 组件，因此组件实例会被复用。而这个钩子就会在这个情况下被调用。

	// 可以访问组件实例 `this`

	console.log('beforeRouteUpdate被调用：在当前路由改变，但是该组件被复用时调用')

	next()

})

router.afterEach((to, from, next) => {
	window, scrollTo(0, 0)
})
export default router