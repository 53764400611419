<!--
 * @author: JJ
 * @since: 2024-07-18
 * ExampleInfoView.vue
-->
<template>
    <div class="container bg-f6" :class="isMobile ? 'mobile' : ''">
        <Header />
        <div class="swiper_link">
            <div class="swiper_sw">
                <div class="swiper_item">
                    <div class="top_banner cu flex flex-center flex-col"
                        :style="{ backgroundImage: `url(${baseInfo?.banner})` }">
                        <div class="top_banner_info tl">
                            <div class="colf fs50 lh60 fwb">{{ language == 'zh' ? baseInfo?.title : baseInfo?.titleEn }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white" :class="isMobile ? 'p30' : 'pt70 pb100'">
            <div class="w1760">
                <div class="title">{{ $t('exampleList.title1') }}</div>
                <div class="flex mt50" v-if="!isMobile">
                    <div class="flex-grow-1 pr110 fs15 lh25 col5a"
                        v-html="language == 'zh' ? baseInfo?.content : baseInfo?.contentEn">

                    </div>
                    <img :src="baseInfo?.cover" class="cover" alt="">
                </div>
                <div class="mt30" v-else>
                    <img :src="baseInfo?.cover" class="cover" alt="">
                    <div class="fs30 mt30 col5a"
                        v-html="language == 'zh' ? baseInfo?.content : baseInfo?.contentEn">

                    </div>
                </div>
            </div>
        </div>
        <div class="product_adv">
            <div class="w1760 " :class="isMobile ? 'p30' : 'pt70 pb30'">
                <div class="title">{{ $t('exampleList.title2') }}</div>
                <div :class="isMobile?'mt30':'mt50 flex'">
                    <div class="product_adv_item" v-if="introduceContent1?.length > 0">
                        <div class="bb tc fs36 fwb col5a">
                            {{ language == 'zh' ? baseInfo?.introduceTitle1 : baseInfo?.introduceTitle1En }}</div>
                        <template v-if="language == 'zh'">
                            <div class="adv_item flex" v-for="item in introduceContent1">
                                <div class="radio"></div>
                                <div class=" flex-grow-1">{{ item }}</div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="adv_item flex" v-for="item in introduceContent1En">
                                <div class="radio"></div>
                                <div class=" flex-grow-1">{{ item }}</div>
                            </div>
                        </template>

                    </div>
                    <div class="product_adv_item" v-if="introduceContent2?.length > 0">
                        <div class="bb tc fs36 fwb col5a">
                            {{ language == 'zh' ? baseInfo?.introduceTitle2 : baseInfo?.introduceTitle2En }}</div>
                        <template v-if="language == 'zh'">
                            <div class="adv_item flex" v-for="item in introduceContent2">
                                <div class="radio"></div>
                                <div class=" flex-grow-1">{{ item }}</div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="adv_item flex" v-for="item in introduceContent2En">
                                <div class="radio"></div>
                                <div class=" flex-grow-1"> {{ item }}</div>
                            </div>
                        </template>

                    </div>
                    <div class="product_adv_item" v-if="introduceContent3?.length > 0">
                        <div class="bb tc fs36 fwb col5a">
                            {{ language == 'zh' ? baseInfo?.introduceTitle3 : baseInfo?.introduceTitle3En }}</div>
                        <template v-if="language == 'zh'">
                            <div class="adv_item flex" v-for="item in introduceContent3">
                                <div class="radio"></div>
                                <div class=" flex-grow-1">{{ item }}</div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="adv_item flex" v-for="item in introduceContent3En">
                                <div class="radio"></div>
                                <div class=" flex-grow-1">{{ item }}</div>
                            </div>
                        </template>

                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white" :class="isMobile ? 'p30' : ' pt70 pb40'" v-if="baseInfo?.disposition">
            <div class="w1760">
                <div class="title">{{ $t('exampleList.title3') }}</div>
                <div class="parameter_rich">
                    <template v-if="language == 'zh'">
                        <div class="par" v-html="baseInfo?.disposition"></div>
                    </template>
                    <template v-else>
                        <div class="par" v-html="baseInfo?.dispositionEn"></div>
                    </template>
                </div>
            </div>
        </div>
        <div class="w1760 " :class="isMobile ? 'p30' : 'pt70 pb130'" v-if="sceneImg?.length > 0 || sceneVideo?.length > 0">
            <div class="title">{{ $t('exampleList.title4') }}</div>
            <div class=" scroll" :class="isMobile ? 'pt30' : 'pt95'">
                <template v-for="item in sceneImg">
                    <img :src="item" class="live_img" alt="">
                </template>
                <template v-for="item in sceneVideo">
                    <video :src="item" class="live_img" controls></video>
                </template>
            </div>
        </div>
        <div class="product_adv">
            <div class="w1760" :class="isMobile ? 'p30' : 'pt70 pb30'">
                <div class="title">{{ $t('exampleList.title5') }}</div>
                <div :class="isMobile ? 'mt30' : 'mt50 flex'">
                    <div class="product_adv_item" v-if="notesContent1?.length > 0">
                        <div class="bb tc fs36 fwb col5a">{{ language == 'zh' ? baseInfo?.notesTitle1 :
                            baseInfo?.notesTitle1En }}
                        </div>
                        <template v-if="language == 'zh'">
                            <div class="adv_item flex" v-for="item in notesContent1">
                                <div class="radio"></div>
                                <div class=" flex-grow-1">{{ item }}</div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="adv_item flex" v-for="item in notesContent1En">
                                <div class="radio"></div>
                                <div class=" flex-grow-1">{{ item }}</div>
                            </div>
                        </template>

                    </div>
                    <div class="product_adv_item" v-if="notesContent2?.length > 0">
                        <div class="bb tc fs36 fwb col5a">{{ language == 'zh' ? baseInfo?.notesTitle2 :
                            baseInfo?.notesTitle2En }}
                        </div>
                        <template v-if="language == 'zh'">
                            <div class="adv_item flex" v-for="item in notesContent2">
                                <div class="radio"></div>
                                <div class=" flex-grow-1">{{ item }}</div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="adv_item flex" v-for="item in notesContent2En">
                                <div class="radio"></div>
                                <div class=" flex-grow-1">{{ item }}</div>
                            </div>
                        </template>

                    </div>
                    <div class="product_adv_item" v-if="notesContent3?.length > 0">
                        <div class="bb tc fs36 fwb col5a">{{ language == 'zh' ? baseInfo?.notesTitle3 :
                            baseInfo?.notesTitle3En }}
                        </div>
                        <template v-if="language == 'zh'">
                            <div class="adv_item flex" v-for="item in notesContent3">
                                <div class="radio"></div>
                                <div class=" flex-grow-1">{{ item }}</div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="adv_item flex" v-for="item in notesContent3En">
                                <div class="radio"></div>
                                <div class=" flex-grow-1">{{ item }}</div>
                            </div>
                        </template>

                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white" v-if="caseList?.length > 0">
            <div class="w1760" :class="isMobile ? 'p30' : 'pt70 pb120'">
                <div class="title">{{ $t('headerTabs.tab3') }}</div>
                <div class="scrol " :class="isMobile ? 'mt30' : 'mt90'">
                    <template v-for="(item, index) in caseList" :key="index">
                        <router-link :to="'/ExampleInfoView?id=' + item.articleId" class="example_link">
                            <div class="example_cover" :style="{ backgroundImage: `url(${item.cover})` }"></div>
                            <div class="m-ellipsis">{{ language == 'zh' ? item.title : item.titleEn }}</div>
                        </router-link>
                    </template>
                </div>
            </div>
        </div>
        <div class="bg-f6 about_product" v-if="productList?.length > 0">
            <div class="w1760" :class="isMobile ? 'p30' : ' pt70 pb100'">
                <div class="title">{{ $t('product.title7') }}</div>
                <div class="prduct_swiper">
                    <swiper @swiper="onSwiper" @slideChange="onSlideChange" class="swiper" :slides-per-view="isMobile ? 1.9 : 4"
                        :observer="true" :observeParents="true" :modules="modules">
                        <swiper-slide class="swiper_item" v-for="(item, index) in productList" :key="index">
                            <router-link :to="'/ProductInfoView?id=' + item.articleId" class="right_item">
                                <img src="../../assets/icon/icon_new.png" v-if="item.isNew" class="icon_new" alt="">
                                <div :style="{ backgroundImage: `url(${item.cover})` }" class="right_cover">
                                </div>

                                <div class="text_nav">
                                    <div class="m-ellipsis fwb col0 fs17 mb20">{{ language == 'zh' ? item.title :
                                        item.titleEn
                                        }}
                                    </div>
                                    <template v-if="language == 'zh'">
                                        <div class="content_text m-ellipsis"
                                            v-for="(item, index) in item?.articleAbstract" :key="index">
                                            {{ item }}</div>
                                    </template>
                                    <template v-else>
                                        <div class="content_text m-ellipsis"
                                            v-for="(item, index) in item?.articleAbstractEn" :key="index">
                                            {{ item }}</div>
                                    </template>
                                </div>
                                <div class="bottom_btn_nav flex-between">
                                    <router-link :to="'/ProductInfoView?id=' + item.articleId" class="btn">{{
                                        $t('product.btn2') }}</router-link>
                                    <router-link :to="'/ProductInfoView?jump=true&id=' + item.articleId"
                                        class="btn cp">{{
                                            $t('product.btn1')
                                        }}</router-link>
                                </div>
                            </router-link>
                        </swiper-slide>
                    </swiper>
                    <template v-if="!isMobile">
                        <img src="../../assets/icon/swiper_l.png" v-if="topActive > 0" class="icon_job1 job_l1"
                        @click="prev" alt="">
                    <img src="../../assets/icon/swiper_l_u.png" v-else class="icon_job1 job_l1" @click="prev" alt="">
                    <img src="../../assets/icon/swiper_r.png" v-if="topActive != 4" class="icon_job1 job_r1"
                        @click="next" alt="">
                    <img src="../../assets/icon/swiper_r_u.png" v-else class="icon_job1 job_r1" @click="next" alt="">
                    </template>
                    
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, watch, inject } from "vue"
import { useRouter } from 'vue-router'
import { Navigation, Pagination, Scrollbar, Autoplay, EffectFade, Parallax } from "swiper";
import { Swiper, SwiperSlide } from "../../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import { useHead } from '@unhead/vue'
const { proxy } = getCurrentInstance();
const router = useRouter()
let language = ref(localStorage.getItem('language') || 'zh');
let dataTitle = ref('')
let dataContent = ref('')
let keywords = ref('')
const modules = [Navigation, Pagination, Scrollbar, Autoplay, EffectFade, Parallax];
const banner = ref(require('../../assets/icon/example_info_banner.png'));
let topActive = ref(0)
let swi = ref('')
const prod_cover = ref(require('../../assets/icon/product_cover.png'))
let id = ref()
let info = ref()
let baseInfo = ref([])
let caseList = ref([])
let productList = ref([])
let introduceContent1 = ref([])
let introduceContent1En = ref([])
let introduceContent2 = ref([])
let introduceContent2En = ref([])
let introduceContent3 = ref([])
let introduceContent3En = ref([])
let notesContent1 = ref([])
let notesContent1En = ref([])
let notesContent2 = ref([])
let notesContent2En = ref([])
let notesContent3 = ref([])
let notesContent3En = ref([])
let sceneImg = ref([])
let sceneVideo = ref([])
let isMobile = inject('isMobile')
const onSwiper = (e) => {
    swi.value = e
}
const onSlideChange = (e) => {
    topActive.value = e.realIndex
}
const prev = () => {
    swi.value.slidePrev();
}
const next = () => {
    swi.value.slideNext()
    console.log(topActive.value);
}
var getApiExampleInfo = (id) => {
    proxy?.$api.apiExampleCategoryInfo({ id }).then(res => {
        if (res.code == 200) {
            info.value = res.data
            baseInfo.value = info.value?.baseInfo
            console.log('base', baseInfo.value);

            caseList.value = info.value?.caseList
            productList.value = info.value?.productList
            if (productList.value?.length > 0) {
                productList.value.map((item) => {
                    item.articleAbstract = item?.articleAbstract?.split("\n")
                    item.articleAbstractEn = item?.articleAbstractEn?.split("\n")
                })
            }
            if (baseInfo.value?.introduceContent1) {
                introduceContent1.value = baseInfo.value?.introduceContent1.split("\n")
            }
            if (baseInfo.value?.introduceContent1En) {
                introduceContent1En.value = baseInfo.value?.introduceContent1En.split("\n")
            }
            if (baseInfo.value?.introduceContent2) {
                introduceContent2.value = baseInfo.value?.introduceContent2.split("\n")
            }
            if (baseInfo.value?.introduceContent2En) {
                introduceContent2En.value = baseInfo.value?.introduceContent2En.split("\n")
            }
            if (baseInfo.value?.introduceContent3) {
                introduceContent3.value = baseInfo.value?.introduceContent3.split("\n")
            }
            if (baseInfo.value?.introduceContent3En) {
                introduceContent3En.value = baseInfo.value?.introduceContent3En.split("\n")
            }
            if (baseInfo.value?.sceneImg) {
                sceneImg.value = baseInfo.value?.sceneImg.split(",")


            }
            if (baseInfo.value?.sceneVideo) {
                sceneVideo.value = baseInfo.value?.sceneVideo.split(",")

            }
            if (baseInfo.value?.notesContent1) {
                notesContent1.value = baseInfo.value?.notesContent1.split("\n")
            }
            if (baseInfo.value?.notesContent1En) {
                notesContent1En.value = baseInfo.value?.notesContent1En.split("\n")
            }
            if (baseInfo.value?.notesContent2) {
                notesContent2.value = baseInfo.value?.notesContent2.split("\n")
            }
            if (baseInfo.value?.notesContent2En) {
                notesContent2En.value = baseInfo.value?.notesContent2En.split("\n")
            }
            if (baseInfo.value?.notesContent3) {
                notesContent3.value = baseInfo.value?.notesContent3.split("\n")
            }
            if (baseInfo.value?.notesContent3En) {
                notesContent3En.value = baseInfo.value?.notesContent3En.split("\n")
            }
        } else {
            proxy?.$message.error(res.msg);
        }

    })
}

onMounted(() => {

    // 使用全局方法
    proxy?.$api.apiBaseInfomation({}).then((data_res) => {
        let res = data_res.data
        if (data_res.code == 200) {
            if (language.value == 'zh') {
                dataTitle = res.siteName
                dataContent = res.description
                keywords = res.keywords
            } else {
                dataTitle = res.siteNameEn
                dataContent = res.descriptionEn
                keywords = res.keywordsEn
            }
            useHead({
                title: dataTitle,
                meta: [
                    { name: 'description', content: dataContent },
                    { property: 'og:title', content: dataTitle },
                    { property: 'og:description', content: dataContent },
                    { name: "keywords", content: keywords }
                ],
            })
        } else {
            proxy?.$message.error(data_res.msg);
        }
    });
});
watch(() =>
    router.currentRoute.value.path,
    (toPath) => {
        if (router.currentRoute.value.query?.id) {
            id.value = router.currentRoute.value.query?.id
            getApiExampleInfo(id.value)
        }

    }, { immediate: true, deep: true });
</script>
<style lang="less" scoped>
.swiper_link {
    width: 100%;
    height: calc(100vw / 1920 * 550);
    position: relative;

    .swiper_sw {
        width: 100%;
        height: calc(100vw / 1920 * 550);
    }

    .swiper_item {
        width: 100%;
        height: 100%;
    }

    .top_banner {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
    }

    .top_banner_info {
        width: 1760px;
        margin-left: auto;
        margin-right: auto;
    }

    .top_btn {
        margin-top: 140px;
        width: 140px;
        height: 54px;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        border: 2px solid #fff;
        border-radius: 10px;
    }
}

.pb100 {
    padding-bottom: 100px;
}

.pr110 {
    padding-right: 110px;
}

.cover {
    width: 800px;
    height: 450px;
    border-radius: 10px;
    object-fit: cover;
}

.product_adv {
    width: 100%;
    min-height: 450px;
    background: #e8e8e8;

}

.product_adv_item {
    width: 500px;
    .bb {
        padding-bottom: 15px;
        border-bottom: 5px solid #1e50e6;
    }

    .adv_item {
        font-size: 24px;
        line-height: 30px;
        color: #5a5a5a;
        margin-top: 15px;
    }

    .adv_item .radio {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #1e50e6;
        margin-right: 10px;
        margin-top: 10px;
    }
}

.product_adv_item+.product_adv_item {
    margin-left: 85px;
}

.parameter_rich {
    // height: 500px;
    width: 100%;
    overflow-y: auto;
    margin-top: 70px;
}

.par {
    width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.pb130 {
    padding-bottom: 130px;
}

.pt95 {
    padding-top: 95px;
}

.scroll {
    width: 100%;
    height: 550px;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}

.live_img {
    width: 550px;
    height: 400px;
    border-radius: 10px;
    object-fit: cover;
    display: inline-block;
    vertical-align: top;

    &+& {
        margin-left: 55px;
    }
}

.pt80 {
    padding-top: 80px;
}

.pt90 {
    padding-top: 90px;
}

.scrol {
    height: 500px;
    overflow-x: auto;
    white-space: nowrap;
}

.example_link {
    width: 420px;
    display: inline-block;
    vertical-align: top;
    font-size: 24px;
    line-height: 40px;
    color: #231414;

    .example_cover {
        display: block;
        width: 420px;
        height: 350px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        transition: all 1s;
        position: relative;
        z-index: 1;
        border-radius: 10px;
        margin-bottom: 40px;
    }

    .example_cover:hover {
        background-size: 110% 110%;
    }

    &+& {
        margin-left: 25px;
    }
}

.example_link:hover {
    color: #1e50e6;
}

.prduct_swiper {
    width: 100%;
    height: 440px;
    margin-top: 75px;
    position: relative;

    .swiper {
        width: 1560px;
        margin-left: auto;
        margin-right: auto;
        height: 440px;
    }
}

.icon_job1 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    cursor: pointer;
    width: 60px;
}

.job_r1 {
    left: auto;
    right: 0;
}

.right_item {
    width: 340px;
    height: 440px;
    border-radius: 10px;
    background: #fff;
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding-top: 30px;
    text-align: center;

    .icon_new {
        width: 68px;
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
    }

    .text_nav {
        margin-top: 25px;
        text-align: left;
        padding: 0 20px;
        font-size: 20px;
        color: #000;
    }

    .content_text {
        position: relative;
        font-size: 13px;
        color: #5a5a5a;



        &::before {
            content: '';
            width: 5px;
            height: 5px;
            display: inline-block;
            vertical-align: middle;
            border-radius: 50%;
            background: #1e50e6;
            margin-right: 10px;
        }
    }

    .content_text+.content_text {
        margin-top: 5px;
    }

    .right_cover {
        width: 190px;
        height: 190px;
        margin-left: auto;
        margin-right: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        transition: all 1s;
    }

    .right_cover:hover {
        background-size: 110% 110%;
    }

    .bottom_btn_nav {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
    }

    .bottom_btn_nav .btn {
        width: 50%;
        height: 68px;
        line-height: 68px;
        text-align: center;
        font-size: 15px;
        color: #1e50e6;
        border-top: 1px solid #d2d2d2;
    }

    .bottom_btn_nav .btn:last-child {
        border-left: 1px solid #d2d2d2;
    }
}

.right_item:nth-of-type(4n) {
    margin-right: 0;
}

.pt70 {
    padding-top: 70px;
}

.mt90 {
    margin-top: 90px;
}

.lh25 {
    line-height: 35px;
}

.mobile {
    .w1760 {
        width: 100%;
    }

    .swiper_link {
        width: 100%;
        height: calc(100vw / 1920 * 650);
        position: relative;
        margin-top: 100px;

        .swiper_sw {
            width: 100%;
            height: calc(100vw / 1920 * 650);
        }

        .swiper_item {
            width: 100%;
            height: 100%;
        }

        .top_banner {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
        }

        .top_banner_info {
            width: 100%;
            padding-left: 30px;
            padding-right: 30px;
            margin-left: auto;
            margin-right: auto;
        }

        .top_btn {
            margin-top: 140px;
            width: 140px;
            height: 54px;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            color: #fff;
            border: 2px solid #fff;
            border-radius: 10px;
        }
    }

    .pb100 {
        padding-bottom: 100px;
    }

    .pr110 {
        padding-right: 110px;
    }

    .cover {
        width: 800px;
        height: 450px;
        border-radius: 10px;
        object-fit: cover;
    }

    .product_adv {
        width: 100%;
        min-height: 450px;
        background: #e8e8e8;

    }

    .product_adv_item {
        width: 650px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        .bb {
            padding-bottom: 15px;
            border-bottom: 5px solid #1e50e6;
        }

        .adv_item {
            font-size: 30px;
            line-height: 38px;
            color: #5a5a5a;
            margin-top: 15px;
        }

        .adv_item .radio {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #1e50e6;
            margin-right: 10px;
            margin-top: 10px;
        }
    }

    .product_adv_item+.product_adv_item {
        margin-left: auto;
    }

    .parameter_rich {
        // height: 500px;
        width: 100%;
        overflow-y: auto;
        margin-top: 30px;
    }

    .par {
        width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .pb130 {
        padding-bottom: 130px;
    }

    .pt95 {
        padding-top: 95px;
    }

    .scroll {
        width: 100%;
        height: 480px;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .live_img {
        width: 550px;
        height: 400px;
        border-radius: 10px;
        object-fit: cover;
        display: inline-block;
        vertical-align: top;

        &+& {
            margin-left: 55px;
        }
    }

    .pt80 {
        padding-top: 80px;
    }

    .pt90 {
        padding-top: 90px;
    }

    .scrol {
        height: 500px;
        overflow-x: auto;
        white-space: nowrap;
    }

    .example_link {
        width: 420px;
        display: inline-block;
        vertical-align: top;
        font-size: 24px;
        line-height: 40px;
        color: #231414;

        .example_cover {
            display: block;
            width: 420px;
            height: 350px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            transition: all 1s;
            position: relative;
            z-index: 1;
            border-radius: 10px;
            margin-bottom: 40px;
        }

        .example_cover:hover {
            background-size: 110% 110%;
        }

        &+& {
            margin-left: 25px;
        }
    }

    .example_link:hover {
        color: #1e50e6;
    }

    .prduct_swiper {
        width: 100%;
        min-height: 440px;
        height: auto;
        margin-top: 30px;
        position: relative;

        .swiper {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            height: auto;
        }
    }

    .icon_job1 {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        cursor: pointer;
        width: 60px;
    }

    .job_r1 {
        left: auto;
        right: 0;
    }

    .right_item {
        width: 340px;
        min-height: 440px;
        height: auto;
        border-radius: 10px;
        background: #fff;
        position: relative;
        display: inline-block;
        vertical-align: top;
        padding-top: 30px;
        text-align: center;

        .icon_new {
            width: 68px;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
        }

        .text_nav {
            margin-top: 25px;
            text-align: left;
            padding: 0 20px;
            font-size: 20px;
            color: #000;
        }

        .content_text {
            position: relative;
            font-size: 20px;
            color: #5a5a5a;



            &::before {
                content: '';
                width: 5px;
                height: 5px;
                display: inline-block;
                vertical-align: middle;
                border-radius: 50%;
                background: #1e50e6;
                margin-right: 10px;
            }
        }

        .content_text+.content_text {
            margin-top: 5px;
        }

        .right_cover {
            width: 190px;
            height: 190px;
            margin-left: auto;
            margin-right: auto;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            transition: all 1s;
        }

        .right_cover:hover {
            background-size: 110% 110%;
        }

        .bottom_btn_nav {
            position: relative;
            bottom: 0;
            left: 0;
            right: 0;
            margin-top: 20px;
            display: flex;
            align-items: center;
        }

        .bottom_btn_nav .btn {
            width: 50%;
            height: 68px;
            line-height: 68px;
            text-align: center;
            font-size: 15px;
            color: #1e50e6;
            border-top: 1px solid #d2d2d2;
        }

        .bottom_btn_nav .btn:last-child {
            border-left: 1px solid #d2d2d2;
        }
    }

    .right_item:nth-of-type(4n) {
        margin-right: 0;
    }

    .pt70 {
        padding-top: 70px;
    }

    .mt90 {
        margin-top: 90px;
    }

    .lh25 {
        line-height: 35px;
    }
}
</style>
