<!--
 * @author: JJ
 * @since: 2024-08-06
 * singleView.vue
-->
<template>
    <div class="container bg-white" :class="isMobile ? 'mobile' : ''">
        <Header :isWhite="true" />
        <div class="pt75 w1760">
            <template v-if="show">
                <div v-html="language == 'zh' ? singleHtml.content : singleHtml.contentEn"></div>
            </template>
        </div>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, getCurrentInstance,inject } from "vue"
const a = ref(0)
import { useHead } from '@unhead/vue'
let language = ref(localStorage.getItem('language') || 'zh');
const { proxy } = getCurrentInstance();
let dataTitle = ref('')
let dataContent = ref('')
let keywords = ref('')
let singleHtml = ref([])
let show = ref(false)
let isMobile = inject('isMobile')
const single = (id) => {
    proxy?.$api.apiSingleInfo({ id }).then(res => {
        if (res.code == 200) {
            singleHtml.value = res.data
            show.value = true
            console.log('single', singleHtml);
        } else {
            proxy?.$message.error(res.msg);
        }
    })
}
onMounted(() => {
    // 使用全局方法
    proxy?.$api.apiBaseInfomation({}).then((data_res) => {
        let res = data_res.data
        if (data_res.code == 200) {
            if (language.value == 'zh') {
                dataTitle = res.siteName
                dataContent = res.description
                keywords = res.keywords
            } else {
                dataTitle = res.siteNameEn
                dataContent = res.descriptionEn
                keywords = res.keywordsEn
            }
            useHead({
                title: dataTitle,
                meta: [
                    { name: 'description', content: dataContent },
                    { property: 'og:title', content: dataTitle },
                    { property: 'og:description', content: dataContent },
                    { name: "keywords", content: keywords }
                ],
            })
        } else {
            proxy?.$message.error(data_res.msg);
        }
    });
    
    single(19)
});
</script>
<style lang="less" scoped>
.pt75 {
    padding-top: 175px;
    padding-bottom: 110px;
}
.mobile{
    .w1760{
        width: 100%;
    }
    .pt75 {
    padding-top: 130px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}
}
</style>
