<template>
    <vue-office-pdf :src="iframeUrl" class="docx-calss" @rendered="renderedHandler" @error="errorHandler" />
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()
let iframeUrl = ref('')
//引入VueOfficePdf组件
import VueOfficePdf from '@vue-office/pdf'
onMounted(() => {
    if (router.currentRoute.value.query?.url) {
        iframeUrl.value = router.currentRoute.value.query?.url
        console.log(iframeUrl.value);
    }

})
const renderedHandler = () => {
    console.log("渲染完成")
}
const errorHandler = () => {
    console.log("渲染失败")
}
//引入相关样式
</script>
<style scoped lang='less'>
.docx-calss {
    height: 100vh;
}
</style>