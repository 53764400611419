<!--
 * @author: JJ
 * @since: 2024-07-17
 * ProductInfoView.vue
-->
<template>
    <div class="container bg-white" :class="isMobile ? 'mobile' : ''">
        <Header :is-white="true" />
        <div class="main">
            <div class="pt130">
                <div class="w1420 h80" :class="isFix ? 'fixed' : ''">
                    <tabNav :tab-list="tabList" :jump="true" :show="true"></tabNav>
                </div>
                <div class="has" id="scroll_item0">
                    <div class="pt125 w1760 flex" v-if="!isMobile">
                        <div class="bt" v-if="swiper_banner.length > 0">
                            <div class="col2 fs20 tc">图片 ({{ swiper_banner.length }})</div>
                            <div class="mt5 flex-box">
                                <div class="pr">
                                    <swiper v-if="swiper_banner.length > 0" :direction="'vertical'" :freeMode="true"
                                        ref="swiper2" watch-slides-progress :slides-per-view="4" :observer="true"
                                        :observeParents="false" :space-between="10" @swiper="setThumbsSwiper"
                                        :modules="modules" class="swiper_banner_mini mt40">
                                        <swiper-slide class=" cu" v-for="(item, index) in swiper_banner" :key="index"
                                            @mouseenter="change(index)">
                                            <div class="banner" :class="midActive == index ? 'ac' : ''"
                                                :style="{ backgroundImage: `url(${item.url})` }">
                                            </div>
                                        </swiper-slide>
                                    </swiper>
                                    <img src="../../assets/icon/arrow_up.png" class="icon_job job_l" @click="prev1"
                                        alt="">
                                    <img src="../../assets/icon/arrow_down.png" class="icon_job job_r" @click="next1"
                                        alt="">

                                </div>
                                <swiper v-if="swiper_banner?.length > 0" watch-slides-progress
                                    @slideChange="slideChange" :modules="modules" :thumbs="{ swiper: thumbsSwiper }"
                                    class="swiper_banner" ref="swiper1" @swiper="setMainSwiper">
                                    <swiper-slide class=" cu" v-for="(item, index) in swiper_banner" :key="index">
                                        <div class="banner" :style="{ backgroundImage: `url(${item.url})` }">
                                        </div>
                                    </swiper-slide>
                                </swiper>
                            </div>

                        </div>
                        <div class=" flex-grow-1 pl130">
                            <div class="fwb fs50 lh50 col0 goods_title m-ellipsis">
                                {{ language == 'zh' ? informaton.baseInfo?.title : informaton.baseInfo?.titleEn }}</div>
                            <div class=" mt45">
                                <div class="fs20 lh36 col5a" v-if="simpleText">
                                    <span v-html="simpleText"></span>
                                </div>
                            </div>
                            <div class="mt55 main_text">
                                <div class="fs20 lh25 fwb col5a mb10">{{ $t('product.sub') }}</div>
                                <div class="content_text m-ellipsis" v-for="(item, index) in mainText" :key="index">
                                    {{ item }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="pt30 w1760 plr30" v-else>
                        <div class="bt" v-if="swiper_banner.length > 0">
                            <div class="col2 fs26 tc">图片 ({{ swiper_banner.length }})</div>
                            <div class="mt5 flex-box">
                                <div class="pr">
                                    <swiper v-if="swiper_banner.length > 0" :direction="'vertical'" :freeMode="true"
                                        ref="swiper2" watch-slides-progress :slides-per-view="4" :observer="true"
                                        :observeParents="false" :space-between="10" @swiper="setThumbsSwiper"
                                        :modules="modules" class="swiper_banner_mini mt40">
                                        <swiper-slide class=" cu" v-for="(item, index) in swiper_banner" :key="index"
                                            @mouseenter="change(index)">
                                            <div class="banner" :class="midActive == index ? 'ac' : ''"
                                                :style="{ backgroundImage: `url(${item.url})` }">
                                            </div>
                                        </swiper-slide>
                                    </swiper>
                                    <img src="../../assets/icon/arrow_up.png" class="icon_job job_l" @click="prev1"
                                        alt="">
                                    <img src="../../assets/icon/arrow_down.png" class="icon_job job_r" @click="next1"
                                        alt="">

                                </div>
                                <swiper v-if="swiper_banner?.length > 0" watch-slides-progress
                                    @slideChange="slideChange" :modules="modules" :thumbs="{ swiper: thumbsSwiper }"
                                    class="swiper_banner" ref="swiper1" @swiper="setMainSwiper">
                                    <swiper-slide class=" cu" v-for="(item, index) in swiper_banner" :key="index">
                                        <div class="banner" :style="{ backgroundImage: `url(${item.url})` }">
                                        </div>
                                    </swiper-slide>
                                </swiper>
                            </div>

                        </div>
                        <div class="mt30 pb30">
                            <div class="fwb fs50 lh50 col0 goods_title m-ellipsis">
                                {{ language == 'zh' ? informaton.baseInfo?.title : informaton.baseInfo?.titleEn }}</div>
                            <div class=" mt45">
                                <div class="fs30 lh34 col5a" v-if="simpleText">
                                    <span v-html="simpleText"></span>
                                </div>
                            </div>
                            <div class="mt30 main_text">
                                <div class="fs30 lh34 fwb col5a mb10">{{ $t('product.sub') }}</div>
                                <div class="content_text m-ellipsis" v-for="(item, index) in mainText" :key="index">
                                    {{ item }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="has" id="scroll_item1">
                    <div class="product_adv bg-f6">
                        <div class="w1760  " :class="isMobile ? 'p30' : 'pt70 pb30'">
                            <div class="title">{{ $t('product.title3') }}</div>
                            <div class="mt50 " :class="isMobile ? '' : 'flex'" v-if="advant_title?.length > 0">
                                <div class="product_adv_item">
                                    <div class="bb tc fs36 fwb col5a mb25">{{ advant_title[0] }}</div>
                                    <template v-if="strlist0?.length > 0">
                                        <div class="adv_item flex" v-for="item in strlist0">
                                            <div class="radio"></div>
                                            <div class="flex-grow-1"><span v-html="item"></span></div>
                                        </div>
                                    </template>
                                </div>
                                <div class="product_adv_item">
                                    <div class="bb tc fs36 fwb col5a mb25">{{ advant_title[1] }}</div>
                                    <template v-if="strlist1?.length > 0">
                                        <div class="adv_item flex" v-for="item in strlist1">
                                            <div class="radio"></div>
                                            <div class="flex-grow-1"><span v-html="item"></span></div>
                                        </div>
                                    </template>
                                </div>
                                <div class="product_adv_item">
                                    <div class="bb tc fs36 fwb col5a mb25">{{ advant_title[2] }}</div>
                                    <template v-if="strlist2?.length > 0">
                                        <div class="adv_item flex" v-for="item in strlist2">
                                            <div class="radio"></div>
                                            <div class="flex-grow-1"><span v-html="item"></span></div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="has" id="scroll_item2">
                    <div class="parameter_nav">
                        <div class="w1760" :class="isMobile ? 'p30' : 'pt70'">
                            <div class="title">{{ $t('product.title4') }}</div>
                            <div class="parameter_rich" v-if="parameter" v-html="parameter">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="has" id="scroll_item3">
                    <div class="bg-f6 upload_nav" v-if="!isMobile">
                        <div class="w1760" :class="isMobile ? 'p30' : 'pt70 pb50'">
                            <div class="title">{{ $t('product.title5') }}</div>
                            <div class="mt40 flex">
                                <div class="left">
                                    <div class="left_item" :class="upindex == index ? 'active' : ''"
                                        v-for="(item, index) in uploadList" @click="chooseUpload(index)" :key="index">{{
                                            item }}</div>
                                </div>
                                <div class="flex-grow-1 pl40">
                                    <div class="right">
                                        <template v-if="upindex == 0 && exampList0?.length > 0">
                                            <div class="upload_item flex-box" v-for="(item, index) in exampList0"
                                                :key="index">
                                                <img src="../../assets/icon/upload_cover.png" class="upload_cover"
                                                    alt="">
                                                <div class="name plr20 fs20 col5a">{{ item.name }}</div>
                                                <a :href="item.url" :download="item.url" target="_blank"
                                                    class="upload_btn flex-box flex-center">
                                                    <img src="../../assets/icon/up1.png" class="upload_ico1" alt="">
                                                    <div>{{ $t('download.btn1') }}</div>
                                                </a>
                                                <router-link :to="'/PDF?url=' + item.url"
                                                    class="upload_btn flex-box flex-center">
                                                    <img src="../../assets/icon/up2.png" class="upload_ico2" alt="">
                                                    <div>{{ $t('download.btn2') }}</div>
                                                </router-link>
                                            </div>

                                        </template>
                                        <template v-if="upindex == 1 && exampList1?.length > 0">
                                            <div class="upload_item flex-box" v-for="(item, index) in exampList1"
                                                :key="index">
                                                <img src="../../assets/icon/upload_cover.png" class="upload_cover"
                                                    alt="">
                                                <div class="name plr20 fs20 col5a">{{ item.name }}</div>
                                                <a :href="item.url" :download="item.url" target="_blank"
                                                    class="upload_btn flex-box flex-center">
                                                    <img src="../../assets/icon/up1.png" class="upload_ico1" alt="">
                                                    <div>{{ $t('download.btn1') }}</div>
                                                </a>
                                                <router-link :to="'/PDF?url=' + item.url"
                                                    class="upload_btn flex-box flex-center">
                                                    <img src="../../assets/icon/up2.png" class="upload_ico2" alt="">
                                                    <div>{{ $t('download.btn2') }}</div>
                                                </router-link>
                                            </div>

                                        </template>
                                        <template v-if="upindex == 2 && exampList2?.length > 0">
                                            <div class="upload_item flex-box" v-for="(item, index) in exampList2"
                                                :key="index">
                                                <img src="../../assets/icon/upload_cover.png" class="upload_cover"
                                                    alt="">
                                                <div class="name plr20 fs20 col5a">{{ item.name }}</div>
                                                <a :href="item.url" :download="item.url" target="_blank"
                                                    class="upload_btn flex-box flex-center">
                                                    <img src="../../assets/icon/up1.png" class="upload_ico1" alt="">
                                                    <div>{{ $t('download.btn1') }}</div>
                                                </a>
                                                <router-link :to="'/PDF?url=' + item.url"
                                                    class="upload_btn flex-box flex-center">
                                                    <img src="../../assets/icon/up2.png" class="upload_ico2" alt="">
                                                    <div>{{ $t('download.btn2') }}</div>
                                                </router-link>
                                            </div>

                                        </template>
                                        <template v-if="upindex == 3 && exampList3?.length > 0">
                                            <div class="upload_item flex-box" v-for="(item, index) in exampList3"
                                                :key="index">
                                                <img src="../../assets/icon/upload_cover.png" class="upload_cover"
                                                    alt="">
                                                <div class="name plr20 fs20 col5a">{{ item.name }}</div>
                                                <a :href="item.url" :download="item.url" target="_blank"
                                                    class="upload_btn flex-box flex-center">
                                                    <img src="../../assets/icon/up1.png" class="upload_ico1" alt="">
                                                    <div>{{ $t('download.btn1') }}</div>
                                                </a>
                                                <router-link :to="'/PDF?url=' + item.url"
                                                    class="upload_btn flex-box flex-center">
                                                    <img src="../../assets/icon/up2.png" class="upload_ico2" alt="">
                                                    <div>{{ $t('download.btn2') }}</div>
                                                </router-link>
                                            </div>

                                        </template>
                                        <template v-if="upindex == 4 && exampList4?.length > 0">
                                            <div class="upload_item flex-box" v-for="(item, index) in exampList4"
                                                :key="index">
                                                <img src="../../assets/icon/upload_cover.png" class="upload_cover"
                                                    alt="">
                                                <div class="name plr20 fs20 col5a">{{ item.name }}</div>
                                                <a :href="item.url" :download="item.url" target="_blank"
                                                    class="upload_btn flex-box flex-center">
                                                    <img src="../../assets/icon/up1.png" class="upload_ico1" alt="">
                                                    <div>{{ $t('download.btn1') }}</div>
                                                </a>
                                                <router-link :to="'/PDF?url=' + item.url"
                                                    class="upload_btn flex-box flex-center">
                                                    <img src="../../assets/icon/up2.png" class="upload_ico2" alt="">
                                                    <div>{{ $t('download.btn2') }}</div>
                                                </router-link>
                                            </div>

                                        </template>
                                        <template v-if="upindex == 5 && exampList5?.length > 0">
                                            <div class="upload_item flex-box" v-for="(item, index) in exampList5"
                                                :key="index">
                                                <img src="../../assets/icon/upload_cover.png" class="upload_cover"
                                                    alt="">
                                                <div class="name plr20 fs20 col5a">{{ item.name }}</div>
                                                <a :href="item.url" :download="item.url" target="_blank"
                                                    class="upload_btn flex-box flex-center">
                                                    <img src="../../assets/icon/up1.png" class="upload_ico1" alt="">
                                                    <div>{{ $t('download.btn1') }}</div>
                                                </a>
                                                <router-link :to="'/PDF?url=' + item.url"
                                                    class="upload_btn flex-box flex-center">
                                                    <img src="../../assets/icon/up2.png" class="upload_ico2" alt="">
                                                    <div>{{ $t('download.btn2') }}</div>
                                                </router-link>
                                            </div>
                                        </template>

                                    </div>
                                    <!-- <pagination-w ref="pagination" :sumCount="total5" :perCount="pageSize"
                                        @pageTurn="get_project">
                                    </pagination-w> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-f6 upload_nav" v-else>
                        <div class="w1760" :class="isMobile ? 'p30' : 'pt70 pb50'">
                            <div class="title">{{ $t('product.title5') }}</div>
                            <div class="mt40">
                                <div class="left_mob">
                                    <div class="left_mob_item" :class="upindex == index ? 'active' : ''"
                                        v-for="(item, index) in uploadList" @click="chooseUpload(index)" :key="index">{{
                                            item }}</div>
                                </div>
                                <div class="">
                                    <div class="right">
                                        <template v-if="upindex == 0 && exampList0?.length > 0">
                                            <div class="upload_item " v-for="(item, index) in exampList0" :key="index">
                                                <div class="flex-box">
                                                    <img src="../../assets/icon/upload_cover.png" class="upload_cover"
                                                        alt="">
                                                    <div class="name flex-grow-1 plr20 fs30 col5a">{{ item.name }}</div>
                                                </div>
                                                <div class="flex-box mt20 flex-end">
                                                    <a :href="item.url" :download="item.url" target="_blank"
                                                        class="upload_btn flex-box flex-center">
                                                        <img src="../../assets/icon/up1.png" class="upload_ico1" alt="">
                                                        <div>{{ $t('download.btn1') }}</div>
                                                    </a>
                                                    <router-link :to="'/PDF?url=' + item.url"
                                                        class="upload_btn flex-box flex-center">
                                                        <img src="../../assets/icon/up2.png" class="upload_ico2" alt="">
                                                        <div>{{ $t('download.btn2') }}</div>
                                                    </router-link>
                                                </div>
                                            </div>

                                        </template>
                                        <template v-if="upindex == 1 && exampList1?.length > 0">
                                            <div class="upload_item" v-for="(item, index) in exampList1"
                                                :key="index">
                                                <div class="flex-box">
                                                    <img src="../../assets/icon/upload_cover.png" class="upload_cover"
                                                        alt="">
                                                    <div class="name flex-grow-1 plr20 fs30 col5a">{{ item.name }}</div>
                                                </div>
                                                <div class="flex-box mt20 flex-end">
                                                    <a :href="item.url" :download="item.url" target="_blank"
                                                        class="upload_btn flex-box flex-center">
                                                        <img src="../../assets/icon/up1.png" class="upload_ico1" alt="">
                                                        <div>{{ $t('download.btn1') }}</div>
                                                    </a>
                                                    <router-link :to="'/PDF?url=' + item.url"
                                                        class="upload_btn flex-box flex-center">
                                                        <img src="../../assets/icon/up2.png" class="upload_ico2" alt="">
                                                        <div>{{ $t('download.btn2') }}</div>
                                                    </router-link>
                                                </div>
                                            </div>

                                        </template>
                                        <template v-if="upindex == 2 && exampList2?.length > 0">
                                            <div class="upload_item" v-for="(item, index) in exampList2"
                                                :key="index">
                                                <div class="flex-box">
                                                    <img src="../../assets/icon/upload_cover.png" class="upload_cover"
                                                        alt="">
                                                    <div class="name flex-grow-1 plr20 fs30 col5a">{{ item.name }}</div>
                                                </div>
                                                <div class="flex-box mt20 flex-end">
                                                    <a :href="item.url" :download="item.url" target="_blank"
                                                        class="upload_btn flex-box flex-center">
                                                        <img src="../../assets/icon/up1.png" class="upload_ico1" alt="">
                                                        <div>{{ $t('download.btn1') }}</div>
                                                    </a>
                                                    <router-link :to="'/PDF?url=' + item.url"
                                                        class="upload_btn flex-box flex-center">
                                                        <img src="../../assets/icon/up2.png" class="upload_ico2" alt="">
                                                        <div>{{ $t('download.btn2') }}</div>
                                                    </router-link>
                                                </div>
                                            </div>

                                        </template>
                                        <template v-if="upindex == 3 && exampList3?.length > 0">
                                            <div class="upload_item" v-for="(item, index) in exampList3"
                                                :key="index">
                                                <div class="flex-box">
                                                    <img src="../../assets/icon/upload_cover.png" class="upload_cover"
                                                        alt="">
                                                    <div class="name flex-grow-1 plr20 fs30 col5a">{{ item.name }}</div>
                                                </div>
                                                <div class="flex-box mt20 flex-end">
                                                    <a :href="item.url" :download="item.url" target="_blank"
                                                        class="upload_btn flex-box flex-center">
                                                        <img src="../../assets/icon/up1.png" class="upload_ico1" alt="">
                                                        <div>{{ $t('download.btn1') }}</div>
                                                    </a>
                                                    <router-link :to="'/PDF?url=' + item.url"
                                                        class="upload_btn flex-box flex-center">
                                                        <img src="../../assets/icon/up2.png" class="upload_ico2" alt="">
                                                        <div>{{ $t('download.btn2') }}</div>
                                                    </router-link>
                                                </div>
                                            </div>

                                        </template>
                                        <template v-if="upindex == 4 && exampList4?.length > 0">
                                            <div class="upload_item" v-for="(item, index) in exampList4"
                                                :key="index">
                                                <div class="flex-box">
                                                    <img src="../../assets/icon/upload_cover.png" class="upload_cover"
                                                        alt="">
                                                    <div class="name flex-grow-1 plr20 fs30 col5a">{{ item.name }}</div>
                                                </div>
                                                <div class="flex-box mt20 flex-end">
                                                    <a :href="item.url" :download="item.url" target="_blank"
                                                        class="upload_btn flex-box flex-center">
                                                        <img src="../../assets/icon/up1.png" class="upload_ico1" alt="">
                                                        <div>{{ $t('download.btn1') }}</div>
                                                    </a>
                                                    <router-link :to="'/PDF?url=' + item.url"
                                                        class="upload_btn flex-box flex-center">
                                                        <img src="../../assets/icon/up2.png" class="upload_ico2" alt="">
                                                        <div>{{ $t('download.btn2') }}</div>
                                                    </router-link>
                                                </div>
                                            </div>

                                        </template>
                                        <template v-if="upindex == 5 && exampList5?.length > 0">
                                            <div class="upload_item" v-for="(item, index) in exampList5"
                                                :key="index">
                                                <div class="flex-box">
                                                    <img src="../../assets/icon/upload_cover.png" class="upload_cover"
                                                        alt="">
                                                    <div class="name flex-grow-1 plr20 fs30 col5a">{{ item.name }}</div>
                                                </div>
                                                <div class="flex-box mt20 flex-end">
                                                    <a :href="item.url" :download="item.url" target="_blank"
                                                        class="upload_btn flex-box flex-center">
                                                        <img src="../../assets/icon/up1.png" class="upload_ico1" alt="">
                                                        <div>{{ $t('download.btn1') }}</div>
                                                    </a>
                                                    <router-link :to="'/PDF?url=' + item.url"
                                                        class="upload_btn flex-box flex-center">
                                                        <img src="../../assets/icon/up2.png" class="upload_ico2" alt="">
                                                        <div>{{ $t('download.btn2') }}</div>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </template>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="has" id="scroll_item4" v-if="informaton?.caseList?.length > 0">
                    <div class="bg-white " :class="isMobile?'p30':'pt65 pb50'">
                        <div class=" w1760 " >
                            <div class="title">{{ $t('headerTabs.tab2') }}</div>
                        </div>
                        <swiper :slides-per-view="isMobile?1:1.42" :centeredSlides="true" :centeredSlidesBounds="true"
                            @swiper="onSwiper1" @slideChange="slideChange1" class="swiper_result" :observer="true"
                            :observeParents="true" :modules="modules" loop>
                            <swiper-slide class="swiper_result_item" v-for="(item, index) in informaton?.caseList"
                                :key="index">
                                <div class="result_item flex flex-col flex-center"
                                    :style="{ backgroundImage: `url(${item.cover})` }">
                                    {{ language == 'zh' ? item.title : item.titleEn }}
                                </div>
                            </swiper-slide>
                        </swiper>
                        <div class="diy_pagination_nav" v-if="!isMobile">
                            <div class="sq"></div>
                            <div class="flex-box flex-center">
                                <div class="sq_item" v-for="(item, index) in informaton?.caseList" :key="index"
                                    @click="changeMid(index)" :class="[midActive1 == index ? 'active' : '']">{{
                                        language == 'zh' ? item.title : item.titleEn }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="has" id="scroll_item5" v-if="informaton?.productList?.length > 0">
                    <div class="bg-f6 about_product">
                        <div class="w1760 pt70 pb100">
                            <div class="title">{{ $t('product.title7') }}</div>
                            <div class="prduct_swiper">
                                <swiper @swiper="onSwiper" @slideChange="onSlideChange" class="swiper"
                                    :slides-per-view="4" :observer="true" :observeParents="true" :modules="modules">
                                    <swiper-slide class="swiper_item" v-for="(item, index) in informaton?.productList"
                                        :key="index">
                                        <router-link :to="'/ProductInfoView?id=' + item.articleId" class="right_item">
                                            <img src="../../assets/icon/icon_new.png" v-if="item.isNew" class="icon_new"
                                                alt="">
                                            <div :style="{ backgroundImage: `url(${item.cover})` }" class="right_cover">
                                            </div>

                                            <div class="text_nav">
                                                <div class="m-ellipsis fwb col0 fs20 mb20">{{ language == 'zh' ?
                                                    item.title :
                                                    item.titleEn }}</div>
                                                <template v-if="language == 'zh'">
                                                    <div class="content_text m-ellipsis"
                                                        v-for="(item, index) in item?.articleAbstract" :key="index">
                                                        {{ item }}</div>
                                                </template>
                                                <template v-else>
                                                    <div class="content_text m-ellipsis"
                                                        v-for="(item, index) in item?.articleAbstractEn" :key="index">
                                                        {{ item }}</div>
                                                </template>
                                            </div>
                                            <div class="bottom_btn_nav flex-between">
                                                <router-link :to="'/ProductInfoView?id=' + item.articleId"
                                                    class="btn">{{
                                                        $t('product.btn2') }}</router-link>
                                                <router-link :to="'/ProductInfoView?jump=true&id=' + item.articleId"
                                                    class="btn cp">{{
                                                        $t('product.btn1')
                                                    }}</router-link>
                                            </div>
                                        </router-link>
                                    </swiper-slide>
                                </swiper>
                                <img src="../../assets/icon/swiper_l.png" v-if="topActive > 0" class="icon_job1 job_l1"
                                    @click="prev" alt="">
                                <img src="../../assets/icon/swiper_l_u.png" v-else class="icon_job1 job_l1"
                                    @click="prev" alt="">
                                <img src="../../assets/icon/swiper_r.png" v-if="topActive != 4" class="icon_job1 job_r1"
                                    @click="next" alt="">
                                <img src="../../assets/icon/swiper_r_u.png" v-else class="icon_job1 job_r1"
                                    @click="next" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <need-help></need-help>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, getCurrentInstance, watch, inject } from "vue"
import { useRouter } from 'vue-router'
import { useHead } from '@unhead/vue'
const { proxy } = getCurrentInstance();
const router = useRouter()
let language = ref(localStorage.getItem('language') || 'zh');
import { Navigation, Pagination, Scrollbar, Autoplay, EffectFade, Parallax, Thumbs, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "../../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import tabNav from "../../components/tabNav.vue";
let dataTitle = ref('')
let dataContent = ref('')
let keywords = ref('')
const t = getCurrentInstance().appContext.config.globalProperties;
const modules = [Navigation, Pagination, Scrollbar, Autoplay, EffectFade, Parallax, Thumbs, FreeMode];
let tabList = ref([{ title: t.$t('product.title9') }, { title: t.$t('product.title3') }, { title: t.$t('product.title4') }, { title: t.$t('product.title5') }])
let isFix = ref(false)
let midActive = ref(0)
const thumbsSwiper = ref(null);
const mainSwiper = ref(null);
let banner1 = ref(require('../../assets/icon/product_banner_info1.png'))
const uploadList = ref([t.$t('download.classify1'), t.$t('download.classify2'), t.$t('download.classify3'), t.$t('download.classify4'), t.$t('download.classify5'), t.$t('download.classify6')]);
let upindex = ref(0)
let swiper_banner = ref([])
let total0 = ref(30)
let total1 = ref(30)
let total2 = ref(30)
let total3 = ref(30)
let total4 = ref(30)
let total5 = ref(30)
let pageSize = ref(9)
let swi1 = ref('')
let swi = ref('')
let midActive1 = ref(0)
let topActive = ref(0)
const prod_cover = ref(require('../../assets/icon/product_cover.png'))
let informaton = ref([])
let id = ref()
let exampList0 = ref([])
let exampList1 = ref([])
let exampList2 = ref([])
let exampList3 = ref([])
let exampList4 = ref([])
let exampList5 = ref([])
const swiper2 = ref()
let summary = ref()
let product_content = ref()
let simpleText = ref()
let mainText = ref()
let advant_title = ref()
let parameter = ref()
let strlist0 = ref()
let strlist1 = ref()
let strlist2 = ref()
let isMobile = inject('isMobile')
// 产品详情接口
var getProductInfo = (id) => {
    proxy?.$api.apiProductInfo({ id }).then((res) => {
        if (res.code == 200) {
            informaton.value = res.data
            // , { title: t.$t('product.title6') }, { title: t.$t('product.title7') }
            if (informaton.value?.caseList?.length > 0) {
                tabList.value.push({ title: t.$t('product.title6') })
            }
            if (informaton.value?.productList?.length > 0) {
                tabList.value.push({ title: t.$t('product.title7') })
            }

            if (informaton.value?.productList) {
                informaton.value?.productList.map((item) => {
                    item.articleAbstract = item?.articleAbstract?.split("\n")
                    item.articleAbstractEn = item?.articleAbstractEn?.split("\n")
                })
            }
            if (language.value == 'zh') {
                if (informaton.value?.details?.data) {
                    if (informaton.value?.details?.data?.loreAttchmentVOS) {
                        informaton.value?.details?.data?.loreAttchmentVOS.map((item, index) => {
                            if (item.typeName == "使用说明书") {
                                exampList0.value.push(item)
                            }
                            if (item.typeName == "彩页/样本") {
                                exampList1.value.push(item)
                            }
                            if (item.typeName == "证书") {
                                exampList2.value.push(item)
                            }
                            if (item.typeName == "图纸") {
                                exampList3.value.push(item)
                            }
                            if (item.typeName == "附件") {
                                exampList4.value.push(item)
                            }
                            if (item.typeName == "应用软件") {
                                exampList5.value.push(item)
                            }
                        })
                    }
                    getInfo(informaton.value?.details?.data)

                }
            } else {
                if (informaton.value?.detailsEn?.data) {
                    console.log(informaton.value?.detailsEn?.data);




                    if (informaton.value?.detailsEn?.data?.loreAttchmentVOS) {
                        informaton.value?.detailsEn?.data?.loreAttchmentVOS.map((item, index) => {
                            if (item.typeName == "使用说明书") {
                                exampList0.value.push(item)
                            }
                            if (item.typeName == "彩页/样本") {
                                exampList1.value.push(item)
                            }
                            if (item.typeName == "证书") {
                                exampList2.value.push(item)
                            }
                            if (item.typeName == "图纸") {
                                exampList3.value.push(item)
                            }
                            if (item.typeName == "附件") {
                                exampList4.value.push(item)
                            }
                            if (item.typeName == "应用软件") {
                                exampList5.value.push(item)
                            }
                        })
                    }
                    getInfo(informaton.value?.detailsEn?.data)

                }
            }

            // console.log('info', informaton.value);
        } else {
            proxy?.$message.error(res.msg);
        }
    })
}
const getInfo = (link) => {
    console.log('link', link);
    summary.value = link?.summary
    product_content.value = link?.content
    swiper_banner.value = link.lorePhotoVOS
    if (summary.value) {
        console.log('完整数据',summary.value);

        var arrstr = summary.value.split('&quot;重要参数&quot;');
        console.log('第1步',arrstr);

        simpleText.value = arrstr[0];
        mainText.value = arrstr[1];
        var arrstr1 = mainText.value.split('</span>')
        console.log('第2步',arrstr1);
        var arrstr2 = arrstr1[1].split('\n  <br /></p>')
        console.log('第3步',arrstr2);
        var arrstr3 = arrstr2[0].split('</p>\n<p>')
        arrstr3.pop()
        mainText.value = arrstr3
    }
    if (product_content.value) {

        var arrstr4 = product_content.value.split('&amp;</p>')
        // var arrstr4 = product_content.value.split('<p>&amp;</p>\n')
        console.log('111', arrstr4);
        var arrstr41 = arrstr4[1].split('<p>&amp;<br /></p>')
        // 其他内容
        parameter.value = arrstr41[0]
        // console.log( parameter.value);

        var str = arrstr4[0].match(/(?<={).*?(?=})/g);
        advant_title.value = str
        // console.log(arrstr4);
        var str1 = arrstr4[0].split('<p>{')
        console.log('123', str1);

        if (str1[0]) {
            strlist0.value = str1[0].match(/(?<=【).*?(?=】)/g)
            console.log(strlist0.value);

        }
        if (str1[1]) {
            strlist1.value = str1[1].match(/(?<=【).*?(?=】)/g)
        }
        if (str1[2]) {
            strlist2.value = str1[2].match(/(?<=【).*?(?=】)/g)
        }
    }
}
watch(() =>
    router.currentRoute.value.path,
    (toPath) => {
        if (router.currentRoute.value.query?.id) {
            id.value = router.currentRoute.value.query?.id
            getProductInfo(id.value)
        }
        //要执行的方法
        if (router.currentRoute.value.query?.jump) {
            let jump = 'scroll_item3'
            setTimeout(() => {
                scrollToAnchor(jump)
            }, 800);
        }


    }, { immediate: true, deep: true });
const scrollToAnchor = (data) => {
    const hash = data; // 注意：根据你的URL结构调整索引
    // console.log(hash);
    if (hash) {
        const element = document.getElementById(hash);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }
}
const seeOnline = (url) => {
    // 将参数转换为查询字符串
    const queryString = `?param=${encodeURIComponent(url)}`;
    // 打开新窗口，并导航到特定的URL
    window.open(`/#/pdf${queryString}`, '_blank');
}
onMounted(() => {
    window.addEventListener('scroll', handleScroll)


    // 使用全局方法
    proxy?.$api.apiBaseInfomation({}).then((data_res) => {
        let res = data_res.data
        if (data_res.code == 200) {
            if (language.value == 'zh') {
                dataTitle = res.siteName
                dataContent = res.description
                keywords = res.keywords
            } else {
                dataTitle = res.siteNameEn
                dataContent = res.descriptionEn
                keywords = res.keywordsEn
            }
            useHead({
                title: dataTitle,
                meta: [
                    { name: 'description', content: dataContent },
                    { property: 'og:title', content: dataTitle },
                    { property: 'og:description', content: dataContent },
                    { name: "keywords", content: keywords }
                ],
            })
        } else {
            proxy?.$message.error(data_res.msg);
        }
    });
});
onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll)
})
const handleScroll = () => {
    // 页面滑动的距离
    let scrollTop = document.documentElement.scrollTop
    // 当页面滑动的距离大于300px时元素显示，否则不显示
    if (scrollTop >= 100) {
        isFix.value = true
    } else {

        isFix.value = false
    }
}
const setThumbsSwiper = (swiper) => {
    thumbsSwiper.value = swiper;
};
const setMainSwiper = (swiper) => {
    mainSwiper.value = swiper
}
const slideChange = (e) => {
    midActive.value = e.realIndex
    // console.log(e);

}
const chooseUpload = (index) => {
    upindex.value = index
}
const prev1 = () => {
    if (midActive.value <= 0) {
        return
    } else {
        midActive.value--

    }
    // console.log(midActive.value);
    thumbsSwiper.value.slidePrev();
    mainSwiper.value.slidePrev()

}
const next1 = () => {
    // console.log(midActive.value);
    if (midActive.value >= swiper_banner.value.length - 1) {
        return
    } else {
        midActive.value++

    }
    // console.log(midActive.value);
    thumbsSwiper.value.slideNext()
    mainSwiper.value.slideNext()


}
const onSwiper = (e) => {
    swi.value = e
}
const onSwiper1 = (e) => {
    swi1.value = e
}
const slideChange1 = (e) => {
    midActive1.value = e.realIndex
}
const change = (e) => {
    midActive.value = e
    thumbsSwiper.value.slideTo(midActive.value, 500)
    mainSwiper.value.slideTo(midActive.value, 500)
    // console.log(thumbsSwiper.value);

}
const changeMid = (e) => {
    // console.log(e);
    swi1.value.slideToLoop(e)
    midActive1.value = e
}
const onSlideChange = (e) => {
    topActive.value = e.realIndex
}
const prev = () => {
    swi.value.slidePrev();
}
const next = () => {
    swi.value.slideNext()
    // console.log(topActive.value);
}


</script>


<style lang="less" scoped>
.main {
    padding-top: 100px;
    position: relative;
}

.pt130 {
    padding-top: 130px;
}

.w1420 {
    max-width: 1420px;
    margin-left: auto;
    margin-right: auto;
    // &.fixed{
    //     position: fixed;
    //     width: 100%;
    //     left: 0;
    //     top: 100px;
    //     background: #fff;
    //     z-index: 30;
    //     max-width: 100%;
    // }
}

.pt125 {
    padding-top: 125px;
    padding-bottom: 115px;
}

.h80 {
    height: 82px;
}

.swiper_banner {
    width: 500px;
    height: 500px;
    margin-left: 70px;

    .banner {
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.swiper_banner_mini {
    width: 80px;
    height: 380px;

    .banner {
        width: 80px;
        height: 80px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        &.ac {
            border: 1px solid #1e50e6;
        }
    }
}

.icon_job {
    position: absolute;
    top: 10px;
    left: 50%;
    margin: auto;
    cursor: pointer;
    width: 12px;
    transform: translateX(-50%);
}

.job_r {
    top: auto;
    bottom: -30px;
}

.bt {
    width: 630px;
    border-top: 3px solid #1e50e6;
    padding-top: 15px;
}

.pl130 {
    padding-left: 130px;
}

.goods_title {
    position: relative;
}

.goods_title::after {
    content: '';
    width: 100px;
    height: 5px;
    background: #1e50e6;
    bottom: 0px;
    left: 0;
    position: absolute;
}

.lh50 {
    line-height: 50px;
    height: 54px;
}

.mt55 {
    margin-top: 55px;
}

.content_text {
    position: relative;
    font-size: 20px;
    color: #5a5a5a;



    &::before {
        content: '';
        width: 5px;
        height: 5px;
        display: inline-block;
        vertical-align: middle;
        border-radius: 50%;
        background: #1e50e6;
        margin-right: 10px;
    }
}

.content_text+.content_text {
    margin-top: 5px;
}

.has {
    padding-top: 100px;
    margin-top: -100px;
}

.product_adv {
    width: 100%;
    min-height: 400px;
    padding-bottom: 40px;
    // background: #e8e8e8;

}

.product_adv_item {
    width: 500px;

    .bb {
        padding-bottom: 15px;
        border-bottom: 5px solid #1e50e6;
    }

    .adv_item {
        font-size: 24px;
        line-height: 30px;
        color: #5a5a5a;
        margin-top: 15px;
    }

    .adv_item .radio {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #1e50e6;
        margin-right: 10px;
        margin-top: 10px;
    }
}

.product_adv_item+.product_adv_item {
    margin-left: 85px;
}

.pt70 {
    padding-top: 70px;
}

.parameter_nav {
    padding-bottom: 50px;

    .parameter_rich {
        height: 500px;
        width: 100%;
        overflow-y: auto;
        margin-top: 70px;

        table {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .par {
        width: 1000px;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.upload_nav {
    .left {
        width: 228px;

        &_item {
            width: 228px;
            height: 60px;
            line-height: 60px;
            border-bottom: 1px solid #d2d2d2;
            font-size: 20px;
            color: #000;
            font-weight: bold;
            cursor: pointer;
        }

        &_item.active {
            color: #1e50e6;
        }

        &_item:first-child {
            border-top: 1px solid #d2d2d2;
        }
    }

    .left_mob {
        width: 100%;
        white-space: nowrap;
        margin-bottom: 30px;
        overflow-x: auto;

        &_item {
            padding: 0 30px;
            height: 60px;
            line-height: 60px;
            font-size: 30px;
            color: #000;
            font-weight: bold;
            cursor: pointer;
            display: inline-block;
            vertical-align: top;
        }

        &_item:first-child {
            padding-left: 0;
        }

        &_item.active {
            color: #1e50e6;
        }
    }

    .right {
        width: 1490px;
        height: 440px;
        overflow-y: auto;
    }

    .upload_item {
        width: 1480px;
        height: 80px;
        padding-left: 70px;
        background: #fff;
        border-radius: 5px;

        .name {
            width: 890px;
        }

        .upload_cover {
            width: 52px;
            height: auto
        }
    }

    .upload_item+.upload_item {
        margin-top: 40px;
    }

    .upload_btn {
        width: 140px;
        height: 30px;
        border: 1px solid #1e50e6;
        border-radius: 5px;
        color: #1e50e6;
        font-size: 20px;
        margin-right: 60px;
    }

    .upload_ico2 {
        margin-right: 10px;
        width: 20px;
        height: auto;
    }

    .upload_ico1 {
        margin-right: 10px;
        width: 17px;
        height: auto;
    }
}

.swiper_result {
    width: 100%;
    height: 650px;
    margin-top: 80px;

    &_item {
        width: 1300px;
        height: 650px;
    }

    .result_item {
        width: 1300px;
        height: 650px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        padding-left: 95px;
        padding-right: 95px;
        font-size: 36px;
        line-height: 110px;
        color: #fff;
        font-weight: bold;
    }
}

.diy_pagination_nav {
    width: 1760px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;

    .sq {
        background: rgb(210, 210, 210);
        width: 1760px;
        height: 2px;
    }

    .sq_item {
        position: relative;
        font-size: 24px;
        color: #5a5a5a;
        text-align: center;
        padding-top: 20px;

        &.active {
            color: rgb(30, 80, 230);
        }

        &.active::before {
            content: '';
            width: 180px;
            height: 5px;
            position: absolute;
            top: -2.5px;
            left: 50%;
            transform: translateX(-50%);
            background: rgb(30, 80, 230);
        }
    }
}

.sq_item+.sq_item {
    margin-left: 120px;
}

.pb100 {
    padding-bottom: 100px;
}

.prduct_swiper {
    width: 100%;
    height: 440px;
    margin-top: 75px;
    position: relative;

    .swiper {
        width: 1560px;
        margin-left: auto;
        margin-right: auto;
        height: 440px;
    }
}

.icon_job1 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    cursor: pointer;
    width: 60px;
}

.job_r1 {
    left: auto;
    right: 0;
}

.right_item {
    width: 340px;
    height: 440px;
    border-radius: 10px;
    background: #fff;
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding-top: 30px;
    text-align: center;

    .icon_new {
        width: 68px;
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
    }

    .text_nav {
        margin-top: 25px;
        text-align: left;
        padding: 0 20px;
        font-size: 20px;
        color: #000;
    }

    .content_text {
        position: relative;
        font-size: 13px;
        color: #5a5a5a;



        &::before {
            content: '';
            width: 5px;
            height: 5px;
            display: inline-block;
            vertical-align: middle;
            border-radius: 50%;
            background: #1e50e6;
            margin-right: 10px;
        }
    }

    .content_text+.content_text {
        margin-top: 5px;
    }

    .right_cover {
        width: 190px;
        height: 190px;
        margin-left: auto;
        margin-right: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        transition: all 1s;
    }

    .right_cover:hover {
        background-size: 110% 110%;
    }

    .bottom_btn_nav {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
    }

    .bottom_btn_nav .btn {
        width: 50%;
        height: 68px;
        line-height: 68px;
        text-align: center;
        font-size: 15px;
        color: #1e50e6;
        border-top: 1px solid #d2d2d2;
    }

    .bottom_btn_nav .btn:last-child {
        border-left: 1px solid #d2d2d2;
    }
}

.right_item:nth-of-type(4n) {
    margin-right: 0;
}

.mobile {
    .main {
        padding-top: 100px;
        position: relative;
    }

    .pt130 {
        padding-top: 130px;
    }

    .w1760 {
        width: 100%;
    }

    .w1420 {
        max-width: 690px;
        margin-left: auto;
        margin-right: auto;
        // &.fixed{
        //     position: fixed;
        //     width: 100%;
        //     left: 0;
        //     top: 100px;
        //     background: #fff;
        //     z-index: 30;
        //     max-width: 100%;
        // }
    }

    .pt125 {
        padding-top: 125px;
        padding-bottom: 115px;
    }

    .h80 {
        height: 82px;
    }

    .swiper_banner {
        width: 500px;
        height: 500px;
        margin-left: 70px;

        .banner {
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    .swiper_banner_mini {
        width: 80px;
        height: 380px;

        .banner {
            width: 80px;
            height: 80px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            &.ac {
                border: 1px solid #1e50e6;
            }
        }
    }

    .icon_job {
        position: absolute;
        top: 10px;
        left: 50%;
        margin: auto;
        cursor: pointer;
        width: 12px;
        transform: translateX(-50%);
    }

    .job_r {
        top: auto;
        bottom: -30px;
    }

    .bt {
        width: 100%;
        border-top: 3px solid #1e50e6;
        padding-top: 15px;
    }

    .pl130 {
        padding-left: 130px;
    }

    .goods_title {
        position: relative;
    }

    .goods_title::after {
        content: '';
        width: 100px;
        height: 5px;
        background: #1e50e6;
        bottom: 0px;
        left: 0;
        position: absolute;
    }

    .lh50 {
        line-height: 50px;
        height: 54px;
    }

    .mt55 {
        margin-top: 55px;
    }

    .content_text {
        position: relative;
        font-size: 26px;
        color: #5a5a5a;



        &::before {
            content: '';
            width: 8px;
            height: 8px;
            display: inline-block;
            vertical-align: middle;
            border-radius: 50%;
            background: #1e50e6;
            margin-right: 10px;
        }
    }

    .content_text+.content_text {
        margin-top: 5px;
    }

    .has {
        padding-top: 100px;
        margin-top: -100px;
    }

    .product_adv {
        width: 100%;
        min-height: 450px;
        padding-bottom: 40px;
        // background: #e8e8e8;

    }

    .product_adv_item {
        width: 550px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;

        .bb {
            padding-bottom: 15px;
            border-bottom: 5px solid #1e50e6;
        }

        .adv_item {
            font-size: 30px;
            line-height: 34px;
            color: #5a5a5a;
            margin-top: 15px;
        }

        .adv_item .radio {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #1e50e6;
            margin-right: 10px;
            margin-top: 10px;
        }
    }

    .product_adv_item+.product_adv_item {
        margin-left: auto;
    }

    .pt70 {
        padding-top: 70px;
    }

    .parameter_nav {
        padding-bottom: 50px;

        .parameter_rich {
            height: 500px;
            width: 100%;
            overflow-y: auto;
            margin-top: 70px;

            table {
                margin-left: auto;
                margin-right: auto;
            }
        }

        .par {
            width: 1000px;
            height: auto;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .upload_nav {
        .left {
            width: 228px;

            &_item {
                width: 228px;
                height: 60px;
                line-height: 60px;
                border-bottom: 1px solid #d2d2d2;
                font-size: 20px;
                color: #000;
                font-weight: bold;
                cursor: pointer;
            }

            &_item.active {
                color: #1e50e6;
            }

            &_item:first-child {
                border-top: 1px solid #d2d2d2;
            }
        }

        .right {
            width: 100%;
            height: 440px;
            overflow-y: auto;
        }

        .upload_item {
            width: 100%;
            height: auto;
            padding: 30px;
            background: #fff;
            border-radius: 10px;

            .name {
                width: auto;
            }

            .upload_cover {
                width: 52px;
                height: auto
            }
        }

        .upload_item+.upload_item {
            margin-top: 40px;
        }

        .upload_btn {
            width: 220px;
            height: 46px;
            border: 1px solid #1e50e6;
            border-radius: 5px;
            color: #1e50e6;
            font-size: 26px;
            margin-right: 60px;
        }

        .upload_ico2 {
            margin-right: 10px;
            width: 25px;
            height: auto;
        }

        .upload_ico1 {
            margin-right: 10px;
            width: 22px;
            height: auto;
        }
    }

    .swiper_result {
        width: 690px;
        margin-left: auto;
        margin-right: auto;
        height: 650px;
        margin-top: 80px;

        &_item {
            width: 100%;
            height: 650px;
        }

        .result_item {
            width: 100%;
            height: 650px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
            font-size: 36px;
            line-height: 42px;
            padding: 30px;
            color: #fff;
            font-weight: bold;
        }
    }

    .diy_pagination_nav {
        width: 1760px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px;

        .sq {
            background: rgb(210, 210, 210);
            width: 1760px;
            height: 2px;
        }

        .sq_item {
            position: relative;
            font-size: 24px;
            color: #5a5a5a;
            text-align: center;
            padding-top: 20px;

            &.active {
                color: rgb(30, 80, 230);
            }

            &.active::before {
                content: '';
                width: 180px;
                height: 5px;
                position: absolute;
                top: -2.5px;
                left: 50%;
                transform: translateX(-50%);
                background: rgb(30, 80, 230);
            }
        }
    }

    .sq_item+.sq_item {
        margin-left: 120px;
    }

    .pb100 {
        padding-bottom: 100px;
    }

    .prduct_swiper {
        width: 100%;
        height: 440px;
        margin-top: 75px;
        position: relative;

        .swiper {
            width: 1560px;
            margin-left: auto;
            margin-right: auto;
            height: 440px;
        }
    }

    .icon_job1 {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        cursor: pointer;
        width: 60px;
    }

    .job_r1 {
        left: auto;
        right: 0;
    }

    .right_item {
        width: 340px;
        height: 440px;
        border-radius: 10px;
        background: #fff;
        position: relative;
        display: inline-block;
        vertical-align: top;
        padding-top: 30px;
        text-align: center;

        .icon_new {
            width: 68px;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
        }

        .text_nav {
            margin-top: 25px;
            text-align: left;
            padding: 0 20px;
            font-size: 20px;
            color: #000;
        }

        .content_text {
            position: relative;
            font-size: 26px;
            color: #5a5a5a;



            &::before {
                content: '';
                width: 8px;
                height: 8px;
                display: inline-block;
                vertical-align: middle;
                border-radius: 50%;
                background: #1e50e6;
                margin-right: 10px;
            }
        }

        .content_text+.content_text {
            margin-top: 5px;
        }

        .right_cover {
            width: 190px;
            height: 190px;
            margin-left: auto;
            margin-right: auto;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            transition: all 1s;
        }

        .right_cover:hover {
            background-size: 110% 110%;
        }

        .bottom_btn_nav {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
        }

        .bottom_btn_nav .btn {
            width: 50%;
            height: 68px;
            line-height: 68px;
            text-align: center;
            font-size: 15px;
            color: #1e50e6;
            border-top: 1px solid #d2d2d2;
        }

        .bottom_btn_nav .btn:last-child {
            border-left: 1px solid #d2d2d2;
        }
    }

    .right_item:nth-of-type(4n) {
        margin-right: 0;
    }
}
</style>
<style>
.parameter_rich::-webkit-scrollbar {
    width: 2px;
    background: #d2d2d2;
}

.parameter_rich::-webkit-scrollbar-thumb {
    background: #5a5a5a;
}

.right::-webkit-scrollbar {
    width: 2px;
    background: #d2d2d2;
}

.right::-webkit-scrollbar-thumb {
    background: #5a5a5a;
}

.parameter_rich table {
    margin-left: auto;
    margin-right: auto;
}

.title::before {
    display: none !important;
}

table {
    width: 100%;
}
</style>
