<template>
    <div class="container"  :class="isMobile ? 'mobile' : ''">
        <Header :isWhite="true"></Header>
        <div class="container pt94">
            <Fixed />
            <template v-if="feedBack">
                <div class="title tc fs40 pb15">{{ $t('form.feedback') }}</div>
                <div class="tc  col0" :class="isMobile ? 'fs26' : 'fs15'"><span class="red">*</span>{{language=='zh'?'为必填项，请您尽量提供详细的信息以便我们更好的为您服务':'* For mandatory fields, please provide detailed information as much as possible so that we can better serve you'}}</div>
                <div class="box " :class="isMobile ? 'pt30' : 'pt60'">
                    <div class=" pb60" :class="isMobile ? '' : 'flex-box flex-between'">
                        <div class="flex-box" :class="isMobile ? 'pb60' : ''">
                            <div class="fs15 col0 fwb titles">{{ $t('form.name') }}<span class="red">*</span></div>
                            <input type="text" class="input plr15" :class="isMobile ? 'flex-grow-1' : ''" :placeholder="$t('form.nametips')" v-model="form.username">
                        </div>
                        <div class="flex-box">
                            <div class="fs15 col0 fwb titles">{{ $t('form.phone') }}<span class="red">*</span></div>
                            <input type="text" class="input plr15" :class="isMobile ? 'flex-grow-1' : ''" :placeholder="$t('form.phonetips')" v-model="form.tel"
                                maxlength="11">
                        </div>
                    </div>
                    <div class="flex-box pb60">
                        <div class="fs15 col0 fwb titles">{{$t('form.type')}}<span class="red">*</span></div>
                        <div class="input plr15 flex-grow-1 flex-box col70 relative pointer"
                            @click.stop="showFeedBack = !showFeedBack">
                            <div class="flex-grow-1" :class="feedBackIndex == -1 ? 'col70' : 'col0'">{{ feedBackIndex ==
                                -1 ? $t('form.typetips') : feedbackList[feedBackIndex].name }}</div>
                            <img src="../assets/icon/arrow_down_gray.png" alt="">
                            <div class="area_list tc" v-if="showFeedBack">
                                <div class="plr30 scroll" v-for="(item, index) in feedbackList"
                                    :key="'feedback' + index" @click.stop="chooseFeedBack(index)">
                                    {{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-box pb60 flex-col-start">
                        <div class="fs15 col0 fwb titles pt5">{{ $t('form.problem') }}<span class="red">*</span></div>
                        <div class="flex-grow-1">
                            <textarea name="" id="" cols="30" rows="10" class="textarea plr15 ptb10"
                                v-model="form.content" :placeholder="$t('form.problemtips')"></textarea>
                        </div>
                    </div>
                    <!-- <div class="flex-box pb60 flex-col-start">
                    <div class="fs15 col0 fwb titles pt5">附件上传<span class="red">*</span></div>
                    <el-upload drag action="https://jsonplaceholder.typicode.com/posts/" :on-change="handleChange"
                        :file-list="fileList" multiple>
                        <div class="flex-box">
                            <div class="relative">
                                <div class="upload fs15">选取文件或拖拽到此处</div>
                            </div>
                            <div class="fs15 col0 pl15">支持jpg、png、gif、rar、zip格式文件，文件小于20MB，且最多可以上传5个文件</div>
                        </div>
                    </el-upload>
                </div> -->
                    <div class="flex-box pb60 flex-col-start">
                        <!-- <div class="fs15 col0 fwb titles pt5">验证码<span class="red">*</span></div> -->
                        <div class="flex-grow-1">
                            <!-- <div class="flex-box pb40">
                            <input type="text" class="input plr15 bl w200" placeholder="请输入验证码">
                            <div class="code pointer">发送验证码</div>
                        </div> -->
                            <div class="flex-box flex-around pt60">
                                <div class="btn1 flex-box flex-center" @click="submit()">
                                    <img src="../assets/icon/icon_submit.png" class="icon_submit" alt="">
                                    {{ $t('form.submit') }}
                                </div>
                                <div class="btn2 flex-box flex-center" @click="open">
                                    <img src="../assets/icon/icon_search.png" class="icon_query" alt="">
                                    {{language=='zh'?'点击查询已提交信息':'Click to query submitted information'}}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </template>
            <template v-else>
                <div class="info" :class="isMobile?'plr30':''">
                    <div class="fs15 col0 flex-box flex-col-start pb60">
                        <div>提交时间：</div>
                        <div class="flex-grow-1">{{ queryInfo.gmtCreate }}</div>
                    </div>
                    <div class="fs15 col0 flex-box flex-col-start pb60">
                        <div>客户姓名：</div>
                        <div class="flex-grow-1">{{ queryInfo.customerName }}</div>
                    </div>
                    <div class="fs15 col0 flex-box flex-col-start pb60">
                        <div>联系方式：</div>
                        <div class="flex-grow-1">{{ queryInfo.tel }}</div>
                    </div>
                    <div class="fs15 col0 flex-box flex-col-start pb60">
                        <div>意见类型：</div>
                        <div class="flex-grow-1">{{ queryInfo.questionType }}</div>
                    </div>
                    <div class="fs15 col0 flex-box flex-col-start pb60">
                        <div>问题描述：</div>
                        <div class="flex-grow-1">{{ queryInfo.content }}</div>
                    </div>
                    <!-- <div class="fs15 col0 flex-box flex-col-start pb150">
                        <div>上传附件：</div>
                        <div class="img_list">
                            <el-upload action="https://jsonplaceholder.typicode.com/posts/" :on-change="handleChange"
                                :file-list="fileList">
                            </el-upload>
                        </div>
                    </div> -->
                    <div class="flex-box fs15 flex-col-start" v-if="!isMobile">
                        <div class="flex-grow-1 flex-box flex-col-start step_item relative active">
                            <img src="../assets/icon/icon_success.png" class="icon_success" alt="">
                            <div class="flex-grow-1">
                                <div class="pt5">提交完成</div>
                                <div class="col70 pt5">自助服务表单已提交</div>
                            </div>
                        </div>
                        <div class="flex-grow-1 flex-box flex-col-start step_item relative">
                            <div class="fs14 num" :class="queryInfo.status >= '0' ? 'active' : ''">2</div>
                            <div class="flex-grow-1">
                                <div class="pt5">待处理</div>
                                <div class="col70 pt5">自助服务表单待客服<br>处理</div>
                            </div>
                        </div>
                        <div class="flex-grow-1 flex-box flex-col-start step_item relative">
                            <div class="fs14 num" :class="queryInfo.status >= '1' ? 'active' : ''">3</div>
                            <div class="flex-grow-1">
                                <div class="pt5">处理中</div>
                                <div class="col70 pt5">自助服务表单已受理，<br>请留意客服来电</div>
                            </div>
                        </div>
                        <div class="flex-grow-1 flex-box flex-col-start step_item relative">
                            <div class="fs14 num" :class="queryInfo.status >= '2' ? 'active' : ''">4</div>
                            <div class="flex-grow-1">
                                <div class="pt5">已完成</div>
                                <div class="col70 pt5">自助服务表单已完成，如有<br>疑问请咨询在线客服，或拨打<br>热线电话：400-817-7777</div>
                            </div>
                        </div>
                    </div>
                    <div class="scro" v-else>
                        <div class="flex-box fs15 flex-col-start aa">
                            <div class="flex-grow-1 flex-box flex-col-start step_item relative active">
                                <img src="../assets/icon/icon_success.png" class="icon_success" alt="">
                                <div class="flex-grow-1">
                                    <div class="pt5">提交完成</div>
                                    <div class="col70 pt5">自助服务表单已提交</div>
                                </div>
                            </div>
                            <div class="flex-grow-1 flex-box flex-col-start step_item relative">
                                <div class="fs14 num" :class="queryInfo.status >= '0' ? 'active' : ''">2</div>
                                <div class="flex-grow-1">
                                    <div class="pt5">待处理</div>
                                    <div class="col70 pt5">自助服务表单待客服<br>处理</div>
                                </div>
                            </div>
                            <div class="flex-grow-1 flex-box flex-col-start step_item relative">
                                <div class="fs14 num" :class="queryInfo.status >= '1' ? 'active' : ''">3</div>
                                <div class="flex-grow-1">
                                    <div class="pt5">处理中</div>
                                    <div class="col70 pt5">自助服务表单已受理，<br>请留意客服来电</div>
                                </div>
                            </div>
                            <div class="flex-grow-1 flex-box flex-col-start step_item relative">
                                <div class="fs14 num" :class="queryInfo.status >= '2' ? 'active' : ''">4</div>
                                <div class="flex-grow-1">
                                    <div class="pt5">已完成</div>
                                    <div class="col70 pt5">自助服务表单已完成，如有<br>疑问请咨询在线客服，或拨打<br>热线电话：400-817-7777</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn1 flex-box flex-center" @click="goback">返回</div>
                </div>
            </template>
        </div>
        <Footer></Footer>
        <popup :visible="popVisible" :confirmText="false" :cancleText="false" @close="close">
            <div class="popup" :class="isMobile ? 'mobile' : ''">
                <div class="title fs40">意见反馈查询</div>
                <div class="tips">仅可查询当前手机号关联的最新一条信息</div>
                <div class="flex-box pb20">
                    <div class="tc fs15 col0 fwb hint">手机号<span class="red">*</span></div>
                    <input type="text" class="flex-grow-1 input plr15" placeholder="请输入11位手机号" v-model="phoneNum"
                        maxlength="11">
                </div>
                <div class="flex-box pb20">
                    <div class="tc fs15 col0 fwb hint">验证码<span class="red">*</span></div>
                    <input type="text" class="flex-grow-1 input plr15 bl" placeholder="请输入验证码" v-model="captcha"
                        maxlength="4">
                    <div @click="refreshCode()" class="code pointer">
                        <s-identify :identifyCode="identifyCode" :content-width="contentW"
                            :content-height="contentH"></s-identify>
                    </div>
                </div>
                <div class="btn1 flex-box flex-center" @click="queryPhone">
                    <img src="../assets/icon/icon_submit.png" class="icon_submit" alt="">
                    提交
                </div>
                <img src="../assets/icon/icon_closepop.png" class="icon_closepop" alt="" @click="close">
            </div>
        </popup>
        <div class="canvas_box" ref="canvasBox"></div>
    </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance,inject } from 'vue'
import { useHead } from '@unhead/vue'
import popup from '../components/popup1.vue';
import sIdentify from "@/components/sIdentify.vue";
export default {
    components: {
        popup,
        sIdentify
    },
    setup() {
        let dataTitle = ref('')
        let dataContent = ref('')
        let keywords = ref('')
        let language = ref(localStorage.getItem('language') || 'zh');
        let isMobile = inject('isMobile')
        onMounted(() => {
      const { proxy } = getCurrentInstance();
      console.log(language);
      // 使用全局方法
      proxy?.$api.apiBaseInfomation({}).then((data_res) => {
        let res = data_res.data
        console.log(res);
        if (data_res.code == 200) {
          if (language.value == 'zh') {
            dataTitle = res.siteName
            dataContent = res.description
            keywords = res.keywords
          } else {
            dataTitle = res.siteNameEn
            dataContent = res.descriptionEn
            keywords = res.keywordsEn
          }
          // serviceHotline.value = res.serviceHotline
          // publicAccountQrcode.value = res.publicAccountQrcode
          useHead({
            title: dataTitle,
            meta: [
              { name: 'description', content: dataContent },
              { property: 'og:title', content: dataTitle },
              { property: 'og:description', content: dataContent },
              { name: "keywords", content: keywords }
            ],
          })
        } else {
          proxy?.$message.error(data_res.msg);
        }
      });
    });
        return {
            dataTitle,
            dataContent,
            keywords,
            language,
            isMobile
        };
    },
    data() {
        return {
            contentW: 0,
            contentH: 0,
            identifyCode: "",
            identifyCodes: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d",], //
            fileList: [{
                name: 'food.jpeg',
                url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
            }, {
                name: 'food2.jpeg',
                url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
            }],
            popVisible: false,
            feedBack: true,
            showFeedBack: false,//显示隐藏意见类型
            feedbackList: [ //意见类型列表
                { name: '产品使用问题', val: 'Productuse' },
                { name: '售后服务问题', val: 'Aftersaissu' },
                { name: '改进意见', val: 'Suggestions' },
                { name: '投诉', val: 'Complaint' },
                { name: '举报不良信息', val: 'Report' },
                { name: '物流建议', val: 'Logistics' },
                { name: '页面卡顿', val: 'Pagestuck' },
                { name: '系统操作复杂', val: 'Complexsystemoperation' },
                { name: '系统反应慢', val: 'Slowsystemresponse' },
                { name: '数据不准', val: 'Inaccuratedata' },
                { name: '其他建议', val: 'OtherRecommendations' },
            ],
            feedBackIndex: -1,
            form: {
                username: '',//姓名
                tel: '',//电话
                content: '',//故障描述
            },
            phoneNum: '',//想要查询的手机号
            queryInfo: {},//查询到的数据
            captcha: '',//验证码
            scrollTop: undefined, // 距离顶端的值
            className: 'modalOpen', // 类名
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.contentW = this.$refs.canvasBox.offsetWidth;
            this.contentH = this.$refs.canvasBox.offsetHeight;
        });
    },
    created() {
        this.refreshCode();
    },
    methods: {
        open() {
            this.popVisible = true;
            this.scrollTop = document.scrollingElement.scrollTop;
            document.body.classList.add(this.className);
            document.body.style.top = `-${this.scrollTop}px`;
        },
        close() {
            this.popVisible = false;
            document.body.classList.remove(this.className);
            document.scrollingElement.scrollTop = this.scrollTop;
        },

        goback() {
            this.$router.replace('/CustomerView')
        },
        randomNum(min, max) {
            max = max + 1;
            return Math.floor(Math.random() * (max - min) + min);
        },
        // 
        refreshCode() {
            this.identifyCode = "";
            this.makeCode(this.identifyCodes, 4);
        },
        // 
        makeCode(data, len) {
            for (let i = 0; i < len; i++) {
                this.identifyCode += this.identifyCodes[
                    this.randomNum(0, this.identifyCodes.length - 1)
                ];
            }
        },
        // 设置选中意见类型
        chooseFeedBack(e) {
            this.feedBackIndex = e;
            this.showFeedBack = false;
        },
        // 验证手机号
        regPhone(num) {
            const reg = /^1[3-9]\d{9}$/;
            return reg.test(num);
        },
        // 提交表单
        submit() {
            let that = this;
            if (this.form.username == '') {
                this.$message.error("请输入用户名");
                return false;
            }
            if (this.form.tel == '') {
                this.$message.error("请输入手机号");
                return false;
            }
            if (this.form.tel !== '') {
                if (!this.regPhone(this.form.tel)) {
                    this.$message.error("请输入正确的11位手机号");
                    return false;
                }
            }
            if (this.feedBackIndex == -1) {
                this.$message.error("请选择意见类型");
                return false;
            }
            if (this.form.content == '') {
                this.$message.error("请输入问题描述");
                return false;
            }

            this.$api.submitForm({
                form: true,
                'tenantId': 'default',
                "columns": [
                    {
                        "name": "template_id",
                        "value": "Feedback"
                    },
                    {
                        "name": "customer_name",
                        "value": this.form.username
                    },
                    {
                        "name": "tel",
                        "value": this.form.tel
                    },
                    {
                        "name": "biz_type",
                        "value": "Feedback"
                    },
                    {
                        "name": "question_type",
                        "value": this.feedbackList[this.feedBackIndex].val
                    },
                    {
                        "name": "content",
                        "value": this.form.content
                    },
                    {
                        "name": "msg_channel",
                        "value": "诺雅克官网"
                    },
                    {
                        "name": "channel_name",
                        "value": "官网"
                    },
                    // {
                    //     "name": "attachment",
                    //     "value": "http://www.chint.com/uploads/license/d2/ddea474fe0d7b26fa4872def5164e5.jpg"
                    // },
                ]
            }).then((res) => {
                if (res.code == 200) {
                    this.$message.success("提交成功");
                    setTimeout(function () {
                        that.$router.replace('/CustomerView')
                    }, 500)
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        // 提交查询手机号
        queryPhone() {
            if (this.phoneNum == '') {
                this.$message.error("请输入手机号");
                return false;
            }
            if (!this.regPhone(this.phoneNum)) {
                this.$message.error("请输入正确的11位手机号");
                return false;
            }
            if (this.captcha == '') {
                this.$message.error("请输入验证码");
                return false;
            }
            if (this.captcha.toUpperCase() !== this.identifyCode.toUpperCase()) {
                this.$message.error("请输入正确的验证码");
                return false;
            }
            this.$api.querySubmit({
                form: true,
                "tel": this.phoneNum,
                "type": "Feedback"
            }).then((res) => {
                if (res.code == 200) {
                    this.queryInfo = res.data;
                    this.feedBack = false;
                    this.close()
                    
                } else {
                    this.$message.error("查询失败，请重试");
                }
            })
        },
        handleChange(file, fileList) {
            this.fileList = fileList.slice(-3);
        },
        input() { }
    }
}
</script>

<style lang="less" scoped>
.canvas_box {
    width: 150px;
    height: 40px;
    position: fixed;
    top: -9999em;
    right: -9999em;
    z-index: -1;
    opacity: 0;
}

.titles {
    min-width: 90px;
    padding-right: 20px;
}

.pointer {
    cursor: pointer;
}

.title {
    color: #706F70;
    font-weight: bold;
    padding-top: 125px;
}

.red {
    color: #FB0A17;
}

.col70 {
    color: #706F70;
}

.input {
    border: 1px solid #B6B6B6;
    width: 300px;
    height: 40px;
    border-radius: 5px;
    font-size: 20px;
}

.textarea {
    border: 1px solid #B6B6B6;
    height: 200px;
    border-radius: 5px;
    resize: none;
    font-size: 20px;
    display: block;
    width: 100%;
}

.textarea::placeholder,
.input::placeholder {
    color: #706F70;
    /* 将颜色改为灰色 */
}

.btn1 {
    width: 250px;
    height: 40px;
    border-radius: 100px;
    background: #1e50e6;
    font-size: 15px;
    color: #fff;
    cursor: pointer;

    .icon_submit {
        width: 18px;
        height: 17px;
        display: block;
        margin-right: 10px;
    }
}

.btn2 {
    width: 250px;
    height: 40px;
    border-radius: 100px;
    background: #fff;
    border: 1px solid #1e50e6;
    font-size: 15px;
    color: #1e50e6;
    cursor: pointer;

    .icon_query {
        width: 18px;
        height: 17px;
        display: block;
        margin-right: 10px;
    }
}

.box {
    width: 920px;
    margin: 0 auto;

    .area_list {
        position: absolute;
        right: 0;
        left: 0;
        top: 39px;
        border: 1px solid #B6B6B6;
        border-top: none;
        background: #fff;
        z-index: 2;
        line-height: 40px;
        cursor: pointer;
    }

    .upload {
        width: 200px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #B6B6B6;
        position: relative;
        z-index: 1;
        color: #706F70;
    }

    .input_up {
        width: 200px;
        height: 40px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        opacity: 0;
        cursor: pointer;
    }


}

.code {
    width: 150px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-size: 15px;
    background: #1e50e6;
    border-radius: 0 5px 5px 0;
}

.input.bl {
    border-right: none;
    border-radius: 5px 0 0 5px;
}

.popup {
    width: 1000px;
    background: #fff;
    border-radius: 10px;
    padding: 40px 50px;

    .title {
        padding-top: 0;
    }

    .tips {
        color: #706F70;
        font-size: 15px;
        padding: 2px 10px;
        background: #D4D4D4;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 50px;
    }

    .hint {
        width: 80px;
    }

    .btn1 {
        width: 100px;
        margin-left: auto;
    }

    .icon_closepop {
        width: 77px;
        height: 76px;
        position: absolute;
        right: -39px;
        bottom: -38px;
        z-index: 10;
        cursor: pointer;
    }
}

.info {
    width: 1260px;
    margin: 0 auto;
    padding-top: 120px;
    padding-bottom: 160px;

    .img_list {
        margin-top: -30px;
    }

    .pb150 {
        padding-bottom: 150px;
    }

    .btn1 {
        margin: 120px auto 0;
    }

    .num {
        width: 30px;
        height: 30px;
        border: 1px solid #717070;
        border-radius: 50%;
        text-align: center;
        line-height: 28px;
        margin-right: 8px;

        &.active {
            background: #1e50e6;
            color: #fff;
            border-color: #1e50e6;
        }
    }

    .icon_success {
        width: 30px;
        height: 30px;
        margin-right: 8px;
    }

    .step_item::after {
        content: "";
        display: block;
        width: 185px;
        height: 1px;
        background: #717070;
        position: absolute;
        top: 16px;
        left: 120px;
    }

    .step_item:last-child::after {
        display: none;
    }

    .step_item.active::after {
        background: #1e50e6;
    }
}

.w200 {
    width: 200px;
}

.scroll:hover {
    background: #1e50e6;
    color: #fff;
    cursor: pointer;
}
.mobile{
    .fs15 {
        font-size: 30px;
    }
    .w1760{
        width: 100%;
    }
    .titles {
        width: 160px;
    }
.pt94{padding-top: 100px;}
.pointer {
    cursor: pointer;
}

.title {
    color: #706F70;
    font-weight: bold;
    padding-top: 125px;
}

.red {
    color: #FB0A17;
}

.col70 {
    color: #706F70;
}

.input {
        border: 1px solid #B6B6B6;
        width: 300px;
        height: 68px;
        border-radius: 5px;
        font-size: 28px;
    }


    .textarea {
        border: 1px solid #B6B6B6;
        height: 200px;
        border-radius: 5px;
        resize: none;
        font-size: 28px;
        display: block;
        width: 100%;
    }


.textarea::placeholder,
.input::placeholder {
    color: #706F70;
    /* 将颜色改为灰色 */
}

.btn1 {
    width: 48%;
        height: 68px;
        border-radius: 100px;
    background: #1e50e6;
    font-size: 30px;
    color: #fff;
    cursor: pointer;

    .icon_submit {
        width: 24px;
            height: 24px;
        display: block;
        margin-right: 10px;
    }
}

.btn2 {
    width: 48%;
        height: 68px;
        border-radius: 100px;
    background: #fff;
    border: 1px solid #1e50e6;
    font-size: 30px;
    color: #1e50e6;
    cursor: pointer;

    .icon_query {
        width: 24px;
        height: 24px;
        display: block;
        margin-right: 10px;
    }
}

.box {
    width: 100%;
    margin: 0 auto;
padding-left: 30px;
padding-right: 30px;
     .area_list {
            position: absolute;
            right: 0;
            left: 0;
            top: 69px;
            border: 1px solid #B6B6B6;
            border-top: none;
            background: #fff;
            z-index: 2;
            line-height: 46px;
            cursor: pointer;
            font-size: 30px;

            .flex-grow-1 {
                max-height: 600px;
                overflow-y: auto;
            }

            .scroll:hover {
                background: #5DB33B;
                color: #fff;
                cursor: pointer;
            }
        }

        .upload {
            width: 200px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 5px;
            border: 1px solid #B6B6B6;
            position: relative;
            z-index: 1;
            color: #706F70;
        }
        .input_up {
            width: 200px;
            height: 40px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            opacity: 0;
            cursor: pointer;
        }


}

.code {
    width: 150px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-size: 15px;
    background: #1e50e6;
    border-radius: 0 5px 5px 0;
}

.input.bl {
    border-right: none;
    border-radius: 5px 0 0 5px;
}

.scro {
        width: 100%;
        white-space: nowrap;
        overflow-x: auto;

        .aa {
            width: 1260px;
        }
    }

.info {
        width: 100%;
        margin: 0 auto;
        padding-top: 120px;
        padding-bottom: 160px;

        .img_list {
            margin-top: -30px;
        }

        .pb150 {
            padding-bottom: 150px;
        }

        .btn1 {
            margin: 120px auto 0;
        }

        .num {
            width: 40px;
            height: 40px;
            border: 1px solid #717070;
            border-radius: 50%;
            text-align: center;
            line-height: 38px;
            margin-right: 8px;

            &.active {
                background: #5DB33B;
                color: #fff;
                border-color: #5DB33B;
            }
        }

        .icon_success {
            width: 40px;
            height: 40px;
            margin-right: 8px;
        }

        .step_item::after {
            content: "";
            display: block;
            width: 165px;
            height: 1px;
            background: #717070;
            position: absolute;
            top: 16px;
            left: 140px;
        }

        .step_item:last-child::after {
            display: none;
        }

        .step_item.active::after {
            background: #5DB33B;
        }
    }

.w200 {
    width: 200px;
}

.scroll:hover {
    background: #1e50e6;
    color: #fff;
    cursor: pointer;
}
}
.popup.mobile {
    width: 690px;
    background: #fff;
    border-radius: 10px;
    padding: 40px 50px;

    .title {
        padding-top: 0;
    }

    .tips {
        color: #706F70;
        font-size: 24px;
        padding: 2px 10px;
        background: #D4D4D4;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 50px;
    }

    .hint {
        width: 120px;
    }

    .code {
        width: 150px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 15px;
        // background: #5DB33B;
        border-radius: 0 5px 5px 0;
        overflow: hidden;
        margin-left: 15px;
    }

    .input.bl {
        border-right: 1px solid #B6B6B6;
        border-radius: 5px 0 0 5px;
    }

    .btn1 {
        width: 240px;
        margin-left: auto;
    }

    .icon_closepop {
        width: 77px;
        height: 76px;
        position: absolute;
        right: -39px;
        bottom: -38px;
        z-index: 10;
        cursor: pointer;
    }

}
</style>
<style>
.el-upload-dragger {
    width: 100% !important;
    border: none !important;
    height: auto !important;
}
</style>