<!--
 * @author: JJ
 * @since: 2024-07-18
 * DownloadView.vue
-->
<template>
    <div class="container" :class="isMobile ? 'mobile' : ''">
        <Header />
        <div class="swiper_link">
            <div class="swiper_sw">
                <div class="swiper_item">
                    <div class="top_banner cu flex flex-center flex-col" :style="{ backgroundImage: `url(${banner})` }">
                        <div class="top_banner_info tl">
                            <div class="colf fs50 lh60 fwb">{{ $t('support.title2') }}</div>
                            <div class="fs24 lh44 colf mt30"><span>{{ language == 'zh' ? span_text : span_text_en }}</span>
                            </div>
                        </div>
                        <div class="tab_nav">
                            <div class="tab_item" :class="nindex == index ? 'active' : ''" @click="chooseNav(index)"
                                v-for="(item, index) in tab" :key="index">{{ item }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div clss="download_nav" v-if="!isMobile">
            <div class="w1760 flex pt40 pb100">
                <div class="left_nav">
                    <div class="search_nav flex-box mb35">
                        <div class="sql"></div>
                        <input type="text" class=" flex-grow-1 fs17 col0" v-model="val" @keyup.enter="search"
                            :placeholder="language == 'zh' ? '请输入关键词' : 'Search'">
                        <img src="../assets/icon/icon_search.png" class="search_ico" @click="search" alt="">
                    </div>
                    <div class="fs25 lh40 fwb col0 mb10">{{ language == 'zh' ? '资料分类' : 'Category' }}</div>
                    <div class="left_link cp" v-for="(item, index) in list" :key="index">
                        <div class="pb30 flex-box" @click="change(index)">
                            <div class=" flex-grow-1 fs17 lh18 col0">
                                {{ item.categoryName }}({{ item.count }})</div>
                            <img :src="qindex == index ? scUrl : ucUrl" class="arrow_down" alt="">
                        </div>
                        <div class="pt8 pb30" v-if="qindex == index">
                            <div class="link_item flex" @click="chooseSub(i, v.articleId)"
                                v-for="(v, i) in item.articleList" :key="i">
                                <img :src="lindex == i ? sc : uc" class="radio" alt="">
                                <div class="col5a flex-grow-1 pl10">{{ language == 'zh' ? v.title : v.titleEn }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1 pl80">
                    <div class="right">
                        <template v-for="(item, index) in downloadList" :key="index">
                            <div class="upload_item flex-box">
                                <img src="../assets/icon/upload_cover.png" class="upload_cover" alt="">
                                <div class="name plr20 fs15 col5a">{{ item.name }}</div>
                                <a :href="item.url" :download="item.url" target="_blank"
                                    class="upload_btn flex-box flex-center">
                                    <img src="../assets/icon/up1.png" class="upload_ico1" alt="">
                                    <div>{{ $t('download.btn1') }}</div>
                                </a>
                                <router-link :to="'/PDF?url=' + item.url" class="upload_btn flex-box flex-center">
                                    <img src="../assets/icon/up2.png" class="upload_ico2" alt="">
                                    <div>{{ $t('download.btn2') }}</div>
                                </router-link>
                            </div>
                        </template>
                    </div>
                    <pagination-w ref="pagination" :sumCount="total" :perCount="pageSize" @pageTurn="get_project">
                    </pagination-w>
                </div>
            </div>
        </div>
        <div clss="download_nav" v-else>
            <div class="w1760" :class="isMobile ? 'p30' : 'pt40 pb100'">
                <div class="left_nav">
                    <div class="search_nav flex-box mb35">
                        <div class="sql"></div>
                        <input type="text" class=" flex-grow-1 fs30 col0" v-model="val" @keyup.enter="search"
                            :placeholder="language == 'zh' ? '请输入关键词' : 'Search'">
                        <img src="../assets/icon/icon_search.png" class="search_ico" @click="search" alt="">
                    </div>
                    <div class="fs36 lh40 fwb col0 mb10">{{ language == 'zh' ? '资料分类' : 'Category' }}</div>
                    <div class="left_link_nav">
                        <div class="left_link cp" v-for="(item, index) in list" :key="index">
                            <div class="pb30 flex-box" @click="change(index)">
                                <div class=" flex-grow-1 fs32 lh34 col0">
                                    {{ item.categoryName }}({{ item.count }})</div>
                                <img :src="qindex == index ? scUrl : ucUrl" class="arrow_down" alt="">
                            </div>
                            <div class="pt8 pb30" v-if="qindex == index">
                                <div class="link_item flex" @click="chooseSub(i, v.articleId)"
                                    v-for="(v, i) in item.articleList" :key="i">
                                    <img :src="lindex == i ? sc : uc" class="radio" alt="">
                                    <div class="col5a flex-grow-1 pl10">{{ language == 'zh' ? v.title : v.titleEn }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <template v-for="(item, index) in downloadList" :key="index">
                        <div class="upload_item">
                            <div class="flex-box">
                                <img src="../assets/icon/upload_cover.png" class="upload_cover" alt="">
                                <div class="name flex-grow-1 plr20 fs30 col5a m-ellipsis-l3">{{ item.name }}</div>
                            </div>
                            <div class="flex-box mt20 flex-end">
                                <a :href="item.url" :download="item.url" target="_blank"
                                    class="upload_btn flex-box flex-center">
                                    <img src="../assets/icon/up1.png" class="upload_ico1" alt="">
                                    <div>{{ $t('download.btn1') }}</div>
                                </a>
                                <router-link :to="'/PDF?url=' + item.url" class="upload_btn flex-box flex-center">
                                    <img src="../assets/icon/up2.png" class="upload_ico2" alt="">
                                    <div>{{ $t('download.btn2') }}</div>
                                </router-link>
                            </div>
                        </div>
                    </template>
                </div>
                <pagination-w ref="pagination" :sumCount="total" :perCount="pageSize" @pageTurn="get_project">
                </pagination-w>
            </div>
        </div>
        <need-help></need-help>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, inject } from "vue"
import { useHead } from '@unhead/vue'
const t = getCurrentInstance().appContext.config.globalProperties;
let language = ref(localStorage.getItem('language') || 'zh');
let dataTitle = ref('')
let dataContent = ref('')
let keywords = ref('')
const banner = ref(require('../assets/icon/download_banner.jpg'));
let span_text = ref('为你提供产品技术、应用、维护相关的\n学习视频和文档，快速导入所需的知识点')
let span_text_en = ref('We provide you with services such as product information download,\n online video learning, after-sales service, pre-sales consultation, and service network inquiry')
const nindex = ref(0);
const tab = ref([t.$t('download.classify1'), t.$t('download.classify2'), t.$t('download.classify3'), t.$t('download.classify4'), t.$t('download.classify5'), t.$t('download.classify6')])
const scUrl = ref(require('../assets/icon/arrow_up_gray.png'))
const ucUrl = ref(require('../assets/icon/arrow_down_gray.png'))
const sc = ref(require('../assets/icon/radio_sc.png'))
const uc = ref(require('../assets/icon/radio_uc.png'))
let list = ref([]);
let qindex = ref(0)
let total = ref(30)
let pageSize = ref(9)
const { proxy } = getCurrentInstance();
let downloadList = ref([])
let categoryId = ref('')
const pagination = ref()
let typeName = ref('使用说明书')
let val = ref()
let lindex = ref(null)
let articleId = ref(null)
let isMobile = inject('isMobile')
const chooseNav = (index, id) => {
    nindex.value = index
    if (index == 0) {
        typeName.value = '使用说明书'
    }
    if (index == 1) {
        typeName.value = '彩页/样本'
    }
    if (index == 2) {
        typeName.value = '认证证书'
    }
    if (index == 3) {
        typeName.value = '图纸'
    }
    if (index == 4) {
        typeName.value = '附件'
    }
    if (index == 5) {
        typeName.value = '应用软件'
    }
    pagination.value.paging.index = 0;
    getDownloadList(1, categoryId.value, val.value, typeName.value, articleId.value)
}
const chooseSub = (i, id) => {
    lindex.value = i
    articleId.value = id
    pagination.value.paging.index = 0;
    getDownloadList(1, null, null, typeName.value, articleId.value)
}
onMounted(() => {
    // 使用全局方法
    proxy?.$api.apiBaseInfomation({}).then((data_res) => {
        let res = data_res.data
        if (data_res.code == 200) {
            if (language.value == 'zh') {
                dataTitle = res.siteName
                dataContent = res.description
                keywords = res.keywords
            } else {
                dataTitle = res.siteNameEn
                dataContent = res.descriptionEn
                keywords = res.keywordsEn
            }
            useHead({
                title: dataTitle,
                meta: [
                    { name: 'description', content: dataContent },
                    { property: 'og:title', content: dataTitle },
                    { property: 'og:description', content: dataContent },
                    { name: "keywords", content: keywords }
                ],
            })
        } else {
            proxy?.$message.error(data_res.msg);
        }
    });
    proxy?.$api.ApiDOwnloadList({}).then(res => {
        if (res.code == 200) {
            console.log('download', res);
            list.value = res.data
        } else {
            this.$message.error(res.msg);
        }
    })
    pagination.value.paging.index = 0;
    getDownloadList(1, null, null, typeName.value, null)
});
const change = ((index, id) => {
    qindex.value = index
    lindex.value = null
    // categoryId.value = id
    // pagination.value.paging.index = 0;
    // getDownloadList(1, id, null,typeName.value)
})
const getDownloadList = (num, id, key, type, articleId) => {
    proxy?.$api.ApiDOwnloadInfo({ 'pageSize': 7, 'pageNum': num, 'categoryId': id, 'name': key, 'typeName': type, 'articleId': articleId }).then(res => {
        if (res.code == 200) {
            console.log('info', res);
            downloadList.value = res.rows
            total.value = res.total
        } else {
            this.$message.error(res.msg);
        }
    })
}
const get_project = ((e) => {
    let page = e + 1;
    if (val.value != undefined) {
        console.log(1);

        getDownloadList(page, null, val.value, typeName.value, null)
    } else if (categoryId.value != '') {
        console.log(categoryId.value);
        getDownloadList(page, categoryId.value, null, typeName.value, null)
    }
    else if (articleId.value != '') {
        getDownloadList(page, null, null, typeName.value, articleId.value)
    } else {
        console.log(3);
        getDownloadList(page, null, null, typeName.value, null)
    }
    window.scrollTo(0, 0)
})
let search = () => {
    console.log(typeof val.value);
    pagination.value.paging.index = 0;
    categoryId.value = ''
    getDownloadList(1, null, val.value, typeName.value, null)
}
</script>
<style lang="less" scoped>
.swiper_link {
    width: 100%;
    height: calc(100vw / 1920 * 550);
    position: relative;

    .swiper_sw {
        width: 100%;
        height: calc(100vw / 1920 * 550);
    }

    .swiper_item {
        width: 100%;
        height: 100%;
    }

    .top_banner {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        position: relative;
    }

    .top_banner_info {
        width: 1760px;
        margin-left: auto;
        margin-right: auto;
    }

    .top_btn {
        margin-top: 140px;
        width: 140px;
        height: 54px;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        border: 2px solid #fff;
        border-radius: 10px;
    }
}

.tab_nav {
    width: 1760px;
    height: 36px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    text-align: center;

    .tab_item {
        min-width: 90px;
        height: 36px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        font-size: 22px;
        color: #acacac;
        line-height: 24px;
        text-align: center;
        cursor: pointer;
    }

    .tab_item+.tab_item {
        margin-left: 140px;
    }

    .tab_item.active {
        font-weight: bold;
        color: #fff;
    }

    .tab_item.active::after {
        content: '';
        width: 90px;
        height: 5px;
        background: #fff;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

.left_nav {
    width: 228px;
}

.search_nav {
    width: 225px;
    height: 40px;
    border-radius: 7.14px;
    border: 0.75px solid rgb(210, 210, 210);
    padding-left: 20px;
    padding-right: 16px;

    .sql {
        margin-right: 6px;
        width: 1px;
        height: 15px;
        background: rgb(114, 113, 113);
    }
}

input::placeholder,
input:-moz-placeholder,
input::-moz-placeholder,
input:-ms-input-placeholder {
    color: rgb(114, 113, 113);
}

.mb35 {
    margin-bottom: 35px;
}

.search_ico {
    width: 16px;
    height: auto;
}

.left_link_nav {
    max-height: 530px;
    overflow-y: auto;
    width: 234px;
    overflow-x: hidden;
}

.left_link {
    padding-top: 20px;
    width: 224px;
    border-top: 0.75px solid rgb(210, 210, 210);

    .arrow_down {
        width: 12px;
        height: auto;
    }

    &:last-child {
        border-bottom: 0.75px solid rgb(210, 210, 210);
    }
}

.pt8 {
    padding-top: 8px;
}

.link_item {
    font-size: 20px;
    line-height: 24px;

    .radio {
        width: 15px;
        height: 15px;
        margin-top: 4px;
        object-fit: cover;
    }

    &+& {
        margin-top: 25px;
    }
}

::-webkit-scrollbar {
    width: 2px;
    background: rgb(213, 213, 213);
}

.right {
    width: 1440px;

}

.upload_item {
    width: 1440px;
    height: 80px;
    padding-left: 70px;
    background: #fff;
    border-radius: 5px;
    border-bottom: 1px solid #ededed;

    .name {
        width: 890px;
    }

    .upload_cover {
        width: 52px;
        height: auto
    }
}

// .upload_item+.upload_item {
//     margin-top: 40px;
// }

.upload_btn {
    width: 140px;
    height: 30px;
    border: 1px solid #1e50e6;
    border-radius: 5px;
    color: #1e50e6;
    font-size: 16px;
    margin-right: 60px;
}

.upload_ico2 {
    margin-right: 10px;
    width: 20px;
    height: auto;
}

.upload_ico1 {
    margin-right: 10px;
    width: 17px;
    height: auto;
}

.pl80 {
    padding-left: 80px;
}

.pb100 {
    padding-bottom: 100px;
}

.mobile {
    .w1760 {
        width: 100%;
    }

    .swiper_link {
        width: 100%;
        height: calc(100vw / 1920 * 750);
        position: relative;
        margin-top: 100px;

        .swiper_sw {
            width: 100%;
            height: calc(100vw / 1920 * 750);
        }

        .swiper_item {
            width: 100%;
            height: 100%;
        }

        .top_banner {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
            position: relative;
        }

        .top_banner_info {
            width: 100%;
            padding-left: 30px;
            padding-right: 30px;
            margin-left: auto;
            margin-right: auto;
        }

        .top_btn {
            margin-top: 140px;
            width: 140px;
            height: 54px;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            color: #fff;
            border: 2px solid #fff;
            border-radius: 10px;
        }
    }
::-webkit-scrollbar{
    display: none;
}
    .tab_nav {
        width: 100%;
        height: 36px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
        text-align: center;
overflow-x: auto;
white-space: nowrap;
padding-left: 30px;
padding-right: 30px;
        .tab_item {
            min-width: 90px;
            height: 36px;
            position: relative;
            display: inline-block;
            vertical-align: top;
            font-size: 26px;
            color: #acacac;
            line-height: 28px;
            text-align: center;
            cursor: pointer;
        }

        .tab_item+.tab_item {
            margin-left: 40px;
        }

        .tab_item.active {
            font-weight: bold;
            color: #fff;
        }

        .tab_item.active::after {
            content: '';
            width: 90px;
            height: 5px;
            background: #fff;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    input::placeholder,
    input:-moz-placeholder,
    input::-moz-placeholder,
    input:-ms-input-placeholder {
        color: rgb(114, 113, 113);
    }

    .mb35 {
        margin-bottom: 35px;
    }

    .left_nav {
        width: 100%;
        padding: 0 30px;
    }

    .search_nav {
        margin-top: 30px;
        width: 100%;
        height: 68px;
        border-radius: 7.14px;
        border: 0.75px solid rgb(210, 210, 210);
        padding-left: 20px;
        padding-right: 16px;

        .sql {
            margin-right: 6px;
            width: 1px;
            height: 26px;
            background: rgb(114, 113, 113);
        }
    }

    .mb35 {
        margin-bottom: 35px;
    }

    .search_ico {
        width: 30px;
        height: auto;
    }

    .left_link_nav {
        max-height: 530px;
        overflow-y: auto;
        width: 100%;
        overflow-x: hidden;
    }

    .left_link {
        padding-top: 20px;
        width: 100%;
        border-top: 0.75px solid rgb(210, 210, 210);

        .arrow_down {
            width: 24px;
            height: auto;
        }

        &:last-child {
            border-bottom: 0.75px solid rgb(210, 210, 210);
        }
    }

    .link_item {
        font-size: 30px;
        line-height: 32px;

        .radio {
            width: 30px;
            height: auto;
        }

        &+& {
            margin-top: 25px;
        }
    }

    ::-webkit-scrollbar {
        width: 2px;
        background: rgb(213, 213, 213);
    }

    .right {
        width: 100%;
margin-top: 30px;
    }

    .upload_item {
        width: 100%;
        height:auto;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 0;
        background: #fff;
        border-radius: 5px;
        border-bottom: 1px solid #ededed;

        .name {
            width: auto;
            display: block;
        }

        .upload_cover {
            width: 60px;
            height: auto
        }
    }

    // .upload_item+.upload_item {
    //     margin-top: 40px;
    // }

    .upload_btn {
            width: 220px;
            height: 46px;
            border: 1px solid #1e50e6;
            border-radius: 5px;
            color: #1e50e6;
            font-size: 26px;
            margin-right: 60px;
        }

        .upload_ico2 {
            margin-right: 10px;
            width: 25px;
            height: auto;
        }

        .upload_ico1 {
            margin-right: 10px;
            width: 22px;
            height: auto;
        }

    .pl80 {
        padding-left: 80px;
    }

    .pb100 {
        padding-bottom: 100px;
    }
}
</style>
