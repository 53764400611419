<template>
    <div class="u_dialog_panel" @click.stop="noop">
      <div class="mask" v-if="visible" @click="handleClose"></div>
      <transition name="fade">
        <div v-if="visible" class="dialog_body">
          <slot></slot>
          <div class="btns" v-if="confirmText || cancleText">
            <div
              class="cancle btn"
              :style="cancleStyle"
              v-if="cancleText"
              @click="handleCancle"
            >
              {{ cancleText }}
            </div>
            <div
              class="confirm btn"
              :style="confirmStyle"
              v-if="confirmText"
              @click="handleConfirm"
            >
              {{ confirmText }}
            </div>
          </div>
        </div>
      </transition>
    </div>
  </template>
  <script>
  export default {
    name: "UDialog",
    props: {
      visible: { type: Boolean, default: false, required: true },
      // 确定按钮的⽂案
      confirmText: { type: [String,Boolean], default: "确定" },
      confirmStyle: { type: String, default: "" },
      // 取消按钮的⽂案
      cancleText: { type: [String,Boolean], default: "取消" },
      cancleStyle: { type: String, default: "" },
    },
    created() {
      this.$nextTick(() => {
        document.body.insertBefore(this.$el, document.body.lastChild);
      });
    },
    beforeMount() {
      // 获取原来的scrollTop 并将body的top修改为对应的值
    },
  
    beforeDestroy() {
  
      document.body.removeChild(this.$el);
    },
    methods: {
      // 关闭弹窗
      handleClose() {
        this.$emit("close");
      },
      // 确定按钮
      handleConfirm() {
        this.$emit("confirm");
      },
      // 取消按钮
      handleCancle() {
        this.$emit("cancle");
      },
      noop() {},
    },
  };
  </script>
  <style lang="less" scoped>
  .u_dialog_panel {
    .mask {
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      left: 0;
      top: 100px;
      z-index: 100;
      backdrop-filter: blur(5px);
    }
    .dialog_body {
      background-color: #fff;
      position: fixed;
      left: 50vw;
      left: 50vw;
      top: 50vh;
      z-index: 102;
      transform: translate(-50%, -50%);
      transform-origin: 0 0;
      border-radius: 20px;
      &.open-enter {
        opacity: 0;
        transform: scale(0.7) translate(-50%, -50%);
        transition: all 0.3s;
      }
      &.open-enter-to {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%);
        transition: all 0.3s;
      }
      .btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 80px 50px;
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 195px;
          height: 60px;
          border-radius: 10px;
          font-size: 18px;
          border-style: solid;
          border-width: 1px;
          &:hover {
            cursor: pointer;
          }
          & + .btn {
            border-left: 1px solid #ddd;
          }
          &.cancle {
            color: #aaa;
            border-color: #aaa;
          }
          &.confirm {
            background-color: var(--blue);
            color: #fff;
          }
        }
      }
    }
  }
  </style>
  