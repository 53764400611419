<!--
 * @author: JJ
 * @since: 2024-07-17
 * ExampleView.vue
-->
<template>
    <div class="container bg-f6" :class="isMobile ? 'mobile' : ''">
        <Header />
        <div class="swiper_link">
            <div class="swiper_sw">
                <div class="swiper_item">
                    <div class="top_banner cu flex flex-center flex-col" :style="{ backgroundImage: `url(${banner})` }">
                        <div class="top_banner_info tl">
                            <div class="colf fs50 lh60 fwb">{{ $t('headerTabs.tab3') }}</div>
                            <div class="fs24 lh28 colf mt30" v-if="language == 'zh'"><span>{{ sub }}</span></div>
                            <div class="fs24 lh28 colf mt30" v-else><span>{{ sub_en }}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="example_nav">
            <div class="w1760 flex" v-if="!isMobile">
                <div class="left_nav">
                    <div class="fs25 lh40 fwb col0" v-if="language == 'zh'">了解客户案例</div>
                    <div class="fs25 lh40 fwb col0" v-else>Customer Case</div>
                    <div class="search_nav flex-box mb35">
                        <div class="sql"></div>
                        <input type="text" class=" flex-grow-1 fs17 col0" v-model="val" @keyup.enter="search"
                            :placeholder="language == 'zh' ? '请输入关键词' : 'Search'">
                        <img src="../assets/icon/icon_search.png" class="search_ico" @click="search" alt="">
                    </div>
                    <div class="left_link_nav">
                        <div class="left_link cp">
                            <div class="pb30 flex-box">
                                <div class=" flex-grow-1 fs19 fwb lh20 col0" v-if="language == 'zh'">按行业</div>
                                <div class=" flex-grow-1 fs19 fwb lh20 col0" v-else>Industry</div>
                                <img :src="scUrl" class="arrow_down" alt="">
                            </div>
                        </div>
                        <div class="left_link cp" v-for="(item, index) in list" :key="index">
                            <div class="pb30 flex-box" @click="change(index)">
                                <div class=" flex-grow-1 fs17 lh18 col0">{{
                                    language == 'zh' ? item.categoryName : item.categoryNameEn }}</div>
                                <img :src="item.choose ? scUrl : ucUrl" class="arrow_down" alt="">
                            </div>
                            <div class="pt8 pb30" v-if="item.childrenList && item.choose">
                                <div class="link_item flex-box" @click="chooseSub(index, i)"
                                    v-for="(v, i) in item.childrenList" :key="i">
                                    <img :src="v.choose ? radioSc : radioUc" class="radio" alt="">
                                    <div class="col5a flex-grow-1 pl10">{{ language == 'zh' ?
                                        v.categoryName : v.categoryNameEn }}（{{ v.productNumber == null ? '0' :
                                        v.productNumber}}）</div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="left_link cp">
                            <div class="pb30 flex-box">
                                <div class=" flex-grow-1 fs19 fwb lh20 col0">按时间</div>
                                <img :src="scUrl" class="arrow_down" alt="">
                            </div>
                        </div>
                        <div class="left_link cp" v-for="(item, index) in list2" :key="index">
                            <div class="pb30 flex-box" @click="change2(index)">
                                <div class=" flex-grow-1 fs17 lh18 col0">{{
                                    item.name }}</div>
                                <img :src="item.choose ? scUrl : ucUrl" class="arrow_down" alt="">
                            </div>
                            <div class="pt8 pb30" v-if="item.list && item.choose">
                                <div class="link_item flex-box" @click="chooseSub2(index, i)"
                                    v-for="(v, i) in item.list" :key="i">
                                    <img :src="v.choose ? radioSc : radioUc" class="radio" alt="">
                                    <div class="col5a flex-grow-1 pl10">{{ v.name }}</div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class=" flex-grow-1 right_nav">
                    <div class="right_item" v-for="(item, index) in exampleList" :key="index">
                        <router-link :to="'/ExampleInfoView?id=' + item.articleId" class="right_item">
                            <div :style="{ backgroundImage: `url(${item.cover})` }" class="cover"></div>
                            <!-- <img :src="item.cover" class="cover" alt=""> -->
                            <div class="text_nav tc" :class="nindex == index ? 'ac' : ''">
                                <span class="m-ellipsis-l2">{{ language == 'zh' ? item.title : item.titleEn }}</span>
                            </div>
                        </router-link>
                    </div>
                    <img src="../assets/icon/null_data.png" class="null" v-if="exampleList.length <= 0" alt="">
                    <!-- sumCount是数据总数50条 perCount是每页有几条 pageTurn是页数切换的方法 每页显示多少条在组件pagination.vue里修改 -->
                    <pagination-w ref="pagination" :sumCount="total" :perCount="pageSize" @pageTurn="get_project">
                    </pagination-w>
                </div>
            </div>
            <div class="w1760" v-else>
                <div class="left_nav">
                    <div class="fs36 lh40 fwb col0" v-if="language == 'zh'">了解客户案例</div>
                    <div class="fs36 lh40 fwb col0" v-else>Customer Case</div>
                    <div class="search_nav flex-box mb35">
                        <div class="sql"></div>
                        <input type="text" class=" flex-grow-1 fs30 col0" v-model="val" @keyup.enter="search"
                            :placeholder="language == 'zh' ? '请输入关键词' : 'Search'">
                        <img src="../assets/icon/icon_search.png" class="search_ico" @click="search" alt="">
                    </div>
                    <div class="left_link_nav">
                        <div class="left_link cp">
                            <div class="pb30 flex-box">
                                <div class=" flex-grow-1 fs34 fwb lh38 col0" v-if="language == 'zh'">按行业</div>
                                <div class=" flex-grow-1 fs34 fwb lh38 col0" v-else>Industry</div>
                                <img :src="scUrl" class="arrow_down" alt="">
                            </div>
                        </div>
                        <div class="left_link cp" v-for="(item, index) in list" :key="index">
                            <div class="pb30 flex-box" @click="change(index)">
                                <div class=" flex-grow-1 fs32 lh34 col0">{{
                                    language == 'zh' ? item.categoryName : item.categoryNameEn }}</div>
                                <img :src="item.choose ? scUrl : ucUrl" class="arrow_down" alt="">
                            </div>
                            <div class="pt8 pb30" v-if="item.childrenList && item.choose">
                                <div class="link_item flex-box" @click="chooseSub(index, i)"
                                    v-for="(v, i) in item.childrenList" :key="i">
                                    <img :src="v.choose ? radioSc : radioUc" class="radio" alt="">
                                    <div class="col5a flex-grow-1 pl10">{{ language == 'zh' ?
                                        v.categoryName : v.categoryNameEn }}（{{ v.productNumber == null ? '0' :
                                        v.productNumber}}）</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right_nav">
                    <div class="right_item" v-for="(item, index) in exampleList" :key="index">
                        <router-link :to="'/ExampleInfoView?id=' + item.articleId" class="right_item">
                            <div :style="{ backgroundImage: `url(${item.cover})` }" class="cover"></div>
                            <div class="text_nav tc" :class="nindex == index ? 'ac' : ''">
                                <span class="m-ellipsis-l2">{{ language == 'zh' ? item.title : item.titleEn }}</span>
                            </div>
                        </router-link>
                    </div>
                    <img src="../assets/icon/null_data.png" class="null" v-if="exampleList.length <= 0" alt="">
                    <!-- sumCount是数据总数50条 perCount是每页有几条 pageTurn是页数切换的方法 每页显示多少条在组件pagination.vue里修改 -->
                    <pagination-w ref="pagination" :sumCount="total" :perCount="pageSize" @pageTurn="get_project">
                    </pagination-w>
                </div>
            </div>
        </div>
        <need-help></need-help>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, watch, inject } from "vue"
import { useHead } from '@unhead/vue'
import { Navigation, Pagination, Scrollbar, Autoplay, EffectFade, Parallax } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
const { proxy } = getCurrentInstance();
import { useRouter } from 'vue-router'
const router = useRouter()
let language = ref(localStorage.getItem('language') || 'zh');
let dataTitle = ref('')
let dataContent = ref('')
let keywords = ref('')
const modules = [Navigation, Pagination, Scrollbar, Autoplay, EffectFade, Parallax];
const banner = ref(require('../assets/icon/example_banner.jpg'));
let sub = ref('以行业成功案例为基础，满足客户个性化需求，\n实现更短的开发周期和更省的开发成本')
let sub_en = ref('Based on successful industry cases, meet personalized customer needs,\nachieve shorter development cycles and lower development costs')
let list = ref([]);
const scUrl = ref(require('../assets/icon/arrow_up_gray.png'))
const ucUrl = ref(require('../assets/icon/arrow_down_gray.png'))
const radioSc = ref(require('../assets/icon/radio_sc.png'))
const radioUc = ref(require('../assets/icon/radio_uc.png'))
const cover = ref(require('../assets/icon/example_cover.png'))
let nindex = ref(0)
let val = ref('')
let total = ref(30)
let pageSize = ref(12)
let id = ref()
let exampleList = ref([])
let parentId = ref()
let isMobile = inject('isMobile')
// 分类接口
var getApiExampleCategory = (id) => {
    proxy?.$api.apiExampleCategory({}).then(res => {
        if (res.code == 200) {
            list.value = res.data;
            let newList = []
            console.log(list.value);
            list.value.map((item, index) => {
                if (id == item.categoryId) {
                    newList.push(Object.assign({}, item, { choose: true }))
                } else {
                    newList.push(Object.assign({}, item, { choose: false }))
                }
                if (item.childrenList) {
                    let it = []
                    list.value[index].childrenList.map((item1) => {
                        it.push(Object.assign({}, item1, { choose: false }))
                    })
                    newList[index].childrenList = it
                }
            })
            list.value = newList;
            // console.log('列表', list.value);
        } else {
            proxy?.$message.error(res.msg);
        }
    })
}
// 列表接口
var getApiExampleList = (num, size, id, key) => {
    proxy?.$api.apiExampleCategoryList({ 'pageNum': num, 'pageSize': size, 'categoryIds': id, 'keywords': key }).then(res => {
        if (res.code == 200) {
            console.log('返回', res.rows);
            exampleList.value = res.rows
            total.value = res.total
            console.log('列表', exampleList.value);
        } else {
            proxy?.$message.error(res.msg);
        }
    })
}
let search = () => {
    console.log(typeof val.value);
    getApiExampleList(1, 12, null, val.value)
}
onMounted(() => {
    // 使用全局方法
    proxy?.$api.apiBaseInfomation({}).then((data_res) => {
        let res = data_res.data
        if (data_res.code == 200) {
            if (language.value == 'zh') {
                dataTitle = res.siteName
                dataContent = res.description
                keywords = res.keywords
            } else {
                dataTitle = res.siteNameEn
                dataContent = res.descriptionEn
                keywords = res.keywordsEn
            }
            useHead({
                title: dataTitle,
                meta: [
                    { name: 'description', content: dataContent },
                    { property: 'og:title', content: dataTitle },
                    { property: 'og:description', content: dataContent },
                    { name: "keywords", content: keywords }
                ],
            })
        } else {
            proxy?.$message.error(data_res.msg);
        }
    });
});

const change = ((index) => {
    list.value[index].choose = !list.value[index].choose
    list.value.map((v, i) => {
        if (i == index) {
            if (v?.childrenList == null) {
                console.log(v);
                id.value = v.categoryId
                console.log(id.value);

                getApiExampleList(1, 12, id.value, null)
            }
            return
        } else {
            v.choose = false
            v?.childrenList?.map((m, n) => {
                m.choose = false
            })
        }
    })

})
const chooseSub = ((index, e) => {
    list.value[index].childrenList[e].choose = !list.value[index].childrenList[e].choose
    list.value[index].childrenList.map((v, i) => {
        if (i == e) {
            id.value = v.categoryId
            return
        } else {
            v.choose = false
        }
    })
    getApiExampleList(1, 12, id.value, null)
})


watch(() =>
    router.currentRoute.value.path,
    (toPath) => {
        if (router.currentRoute.value.query?.id) {
            id.value = router.currentRoute.value.query?.id
            getApiExampleCategory(id.value)
            getApiExampleList(1, 12, id.value, null)

        } else {
            getApiExampleCategory(null)
            getApiExampleList(1, 12, null, null)
        }

    }, { immediate: true, deep: true })
const get_project = ((e) => {
    let page = e + 1;
    if (val.value != undefined) {
        getApiExampleList(page, 12, null, val.value)
    } else if (id.value != undefined) {
        getApiExampleList(page, 12, null, id.value)
    }
    else {
        getApiExampleList(page, 12, null, null)
    }

    window.scrollTo(0, 0)
})
</script>
<style lang="less" scoped>
.swiper_link {
    width: 100%;
    height: calc(100vw / 1920 * 550);
    position: relative;

    .swiper_sw {
        width: 100%;
        height: calc(100vw / 1920 * 550);
    }

    .swiper_item {
        width: 100%;
        height: 100%;
    }

    .top_banner {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
    }

    .top_banner_info {
        width: 1760px;
        margin-left: auto;
        margin-right: auto;
    }

    .top_btn {
        margin-top: 140px;
        width: 140px;
        height: 54px;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        border: 2px solid #fff;
        border-radius: 10px;
    }
}

.left_nav {
    width: 228px;
}

.search_nav {
    margin-top: 35px;
    width: 225px;
    height: 40px;
    border-radius: 7.14px;
    border: 0.75px solid rgb(210, 210, 210);
    padding-left: 20px;
    padding-right: 16px;

    .sql {
        margin-right: 6px;
        width: 1px;
        height: 15px;
        background: rgb(114, 113, 113);
    }
}

input::placeholder,
input:-moz-placeholder,
input::-moz-placeholder,
input:-ms-input-placeholder {
    color: rgb(114, 113, 113);
}

.mb35 {
    margin-bottom: 35px;
}

.search_ico {
    width: 16px;
    height: auto;
}

.left_link_nav {
    max-height: 530px;
    overflow-y: auto;
    width: 234px;
    overflow-x: hidden;
}

.left_link {
    padding-top: 20px;
    width: 224px;
    border-top: 0.75px solid rgb(210, 210, 210);

    .arrow_down {
        width: 12px;
        height: auto;
    }

    &:last-child {
        border-bottom: 0.75px solid rgb(210, 210, 210);
    }
}

.pt8 {
    padding-top: 8px;
}

.link_item {
    font-size: 20px;
    line-height: 20px;

    .radio {
        width: 15px;
        height: auto;
    }

    &+& {
        margin-top: 25px;
    }
}

::-webkit-scrollbar {
    width: 2px;
    background: rgb(213, 213, 213);
}

.right_nav {
    padding-left: 100px;
    white-space: wrap;
    .shadow {
        box-shadow: 0 0 10px rgba(92, 181, 49, .75);
    }

    .right_item {
        width: 320px;
        height: 350px;
        border-radius: 14px;
        background: #fff;
        display: inline-block;
        vertical-align: top;
        margin-right: 45px;
        margin-top: 30px;
        position: relative;

        .cover {
            display: block;
            width: 320px;
            height: 220px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            transition: all 1s;
            position: relative;
            z-index: 1;
            border-radius: 15px 15px 0 0;
        }

        .cover:hover {
            background-size: 110% 110%;
        }

        .text_nav {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 2;
            height: 130px;
            padding: 20px 15px 10px;
            font-size: 25px;
            line-height: 40px;
            color: #5a5a5a;
            font-weight: bold;
            border-radius: 0 0 15px 15px;
        }
    }

    // .right_item:hover {
    //     box-shadow: 0 0 10px rgba(92, 181, 49, .75);
    // }

    .right_item:hover .text_nav {
        background: linear-gradient(-90deg, #1e50e6, #1e50e6);
        color: #fff;

    }

    .right_item:nth-of-type(4n) {
        margin-right: 0;
    }

    .right_item:nth-of-type(-n + 4) {
        margin-top: 0;
    }
}

.example_nav {
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: 1300px;
}

.null {
    width: 300px;
    height: 300px;
    display: block;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
    margin-top: 150px;
}

.mobile {
    .w1760 {
        width: 100%;
    }

    .swiper_link {
        width: 100%;
        height: calc(100vw / 1920 * 550);
        position: relative;
        margin-top: 100px;

        .swiper_sw {
            width: 100%;
            height: calc(100vw / 1920 * 550);
        }

        .swiper_item {
            width: 100%;
            height: 100%;
        }

        .top_banner {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
        }

        .top_banner_info {
            width: 100%;
            padding-left: 30px;
            padding-right: 30px;
            margin-left: auto;
            margin-right: auto;
        }

        .top_btn {
            margin-top: 140px;
            width: 140px;
            height: 54px;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            color: #fff;
            border: 2px solid #fff;
            border-radius: 10px;
        }
    }

    .left_nav {
        width: 100%;
         padding: 0 30px;
    }

    .search_nav {
        margin-top: 30px;
        width: 100%;
    height: 68px;
    border-radius: 7.14px;
    border: 0.75px solid rgb(210, 210, 210);
    padding-left: 20px;
    padding-right: 16px;

        .sql {
            margin-right: 6px;
            width: 1px;
            height: 15px;
            background: rgb(114, 113, 113);
        }
    }

    input::placeholder,
    input:-moz-placeholder,
    input::-moz-placeholder,
    input:-ms-input-placeholder {
        color: rgb(114, 113, 113);
    }

    .mb35 {
        margin-bottom: 35px;
    }

    .search_ico {
        width: 30px;
        height: auto;
    }

    .left_link_nav {
        max-height: 530px;
        overflow-y: auto;
        width: 100%;
        overflow-x: hidden;
    }

    .left_link {
        padding-top: 20px;
        width: 100%;
        border-top: 0.75px solid rgb(210, 210, 210);

        .arrow_down {
            width: 24px;
            height: auto;
        }

        &:last-child {
            border-bottom: 0.75px solid rgb(210, 210, 210);
        }
    }

    .pt8 {
        padding-top: 8px;
    }

    .link_item {
        font-size: 30px;
    line-height: 32px;

        .radio {
            width: 30px;
            height: auto;
        }

        &+& {
            margin-top: 25px;
        }
    }

    ::-webkit-scrollbar {
        width: 2px;
        background: rgb(213, 213, 213);
    }

    .right_nav {
        padding-left: 30px;
    padding-right: 30px;
    white-space: wrap;
    padding-top: 40px;
        .shadow {
            box-shadow: 0 0 10px rgba(92, 181, 49, .75);
        }

        .right_item {
            width: 320px;
            height: 350px;
            border-radius: 14px;
            background: #fff;
            display: inline-block;
            vertical-align: top;
            margin-right: 30px;
            margin-top: 30px;
            position: relative;

            .cover {
                display: block;
                width: 320px;
                height: 220px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                transition: all 1s;
                position: relative;
                z-index: 1;
                border-radius: 15px 15px 0 0;
            }

            .cover:hover {
                background-size: 110% 110%;
            }

            .text_nav {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 2;
                height: 130px;
                padding: 20px 15px 10px;
                font-size: 25px;
                line-height: 40px;
                color: #5a5a5a;
                font-weight: bold;
                border-radius: 0 0 15px 15px;
            }
        }

        // .right_item:hover {
        //     box-shadow: 0 0 10px rgba(92, 181, 49, .75);
        // }

        .right_item:hover .text_nav {
            background: linear-gradient(-90deg, #1e50e6, #1e50e6);
            color: #fff;

        }

        .right_item:nth-of-type(2n) {
            margin-right: 0;
        }

        .right_item:nth-of-type(-n + 2) {
            margin-top: 0;
        }
    }

    .example_nav {
        padding-top: 50px;
        padding-bottom: 50px;
        min-height: 1300px;
    }

    .null {
        width: 300px;
        height: 300px;
        display: block;
        object-fit: cover;
        margin-left: auto;
        margin-right: auto;
        margin-top: 150px;
    }
}
</style>
