<!--
 * @author: JJ
 * @since: 2024-07-22
 * NewsInfoView.vue
-->
<template>
    <div class="container bg-f6">
        <Header :isWhite="true" />
        <div class="pt75 pb110">
            <div class="main w1760">
                <div class=" flex-box">
                    <img src="../../assets/icon/icon_home.png" class=" ico16" alt="">
                    <router-link to="/NewsView" class="fs15 col5a ml5">{{ $t('newsList.title') }}</router-link>
                    <div class="fs15 col5a mlr15">></div>
                    <router-link to="/NewsView" class="fs15 col5a">{{ $t('indexHtml.tab_text1') }}</router-link>
                </div>
                <div class="flex mt50">
                    <div class="pr65 flex-grow-1">
                        <div class="bb pb15">
                            <div class="tc fwb fs36 lh38 col0 mb75">{{ language == 'zh' ? list.title : list.titleEn }}
                            </div>
                            <div class="fs14 col5a flex-box flex-center">
                                <div class="tc">{{ $t('newsList.time') }}：{{ list.updateTime }}</div>
                                <!-- <div class="w200 flex-box flex-center">
                                    <img src="../../assets/icon/icon_view.png" class="ico20" alt="">
                                    <div class="ml5">浏览量 : 205</div>
                                </div> -->
                                <!-- <div class="w200 flex-box flex-center">
                                    <div class="mr5">分享：</div>
                                    <img src="../../assets/icon/weixin.png" class="ico19" alt="">
                                    <img src="../../assets/icon/weibo.png" class="ico19" alt="">
                                </div> -->
                            </div>
                        </div>
                        <div class="mt80 fs15">
                            <div v-if="language == 'zh'" v-html="list.content"></div>
                            <div v-else v-html="list.contentEn"></div>
                        </div>
                    </div>
                    <div class="recomd_list" v-if="recomd && recomd.length > 0">
                        <div class="fs24 col0">{{ $t('newsList.tips') }}</div>
                        <!-- -->
                        <template v-if="recomd[0]?.thirdPartyPlatformUrl">
                            <a :href="recomd[0]?.thirdPartyPlatformUrl" class="recomd_item"
                                :style="{ backgroundImage: `url(${recomd[0].cover})` }">
                                <img src="../../assets/icon/moudle.png" class=" moudle" alt="">
                                <div class="pr z2 flex flex-col flex-end news_recomd_view">
                                    <div class="fs16">{{ recomd[0].updateTime }}</div>
                                    <div class="fs16 mt10">{{ language == 'zh' ? recomd[0].title : recomd[0].titleEn }}
                                    </div>
                                </div>
                            </a>
                        </template>
                        <template v-else>
                            <router-link :to="'/NewsInfoView?id=' + recomd[0].articleId" class="recomd_item"
                                :style="{ backgroundImage: `url(${recomd[0].cover})` }">
                                <img src="../../assets/icon/moudle.png" class=" moudle" alt="">
                                <div class="pr z2 flex flex-col flex-end news_recomd_view">
                                    <div class="fs16">{{ recomd[0].updateTime }}</div>
                                    <div class="fs16 mt10">{{ language == 'zh' ? recomd[0].title : recomd[0].titleEn }}
                                    </div>
                                </div>
                            </router-link>

                        </template>
                        <!--  -->
                        <template v-if="recomd[1]?.thirdPartyPlatformUrl">
                            <a :href="recomd[1]?.thirdPartyPlatformUrl" class="recomd_item" :style="{ backgroundImage: `url(${recomd[1].cover})` }">
                                <img src="../../assets/icon/moudle.png" class=" moudle" alt="">
                                <div class="pr z2 flex flex-col flex-end news_recomd_view">
                                    <div class="fs16">{{ recomd[1].updateTime }}</div>
                                    <div class="fs16 mt10">{{ language == 'zh' ? recomd[1].title : recomd[1].titleEn }}
                                    </div>
                                </div>
                            </a>
                        </template>
                        <template v-else>
                            <router-link :to="'/NewsInfoView?id=' + recomd[1].articleId" class="recomd_item" :style="{ backgroundImage: `url(${recomd[1].cover})` }">
                                <img src="../../assets/icon/moudle.png" class=" moudle" alt="">
                                <div class="pr z2 flex flex-col flex-end news_recomd_view">
                                    <div class="fs16">{{ recomd[1].updateTime }}</div>
                                    <div class="fs16 mt10">{{ language == 'zh' ? recomd[1].title : recomd[1].titleEn }}
                                    </div>
                                </div>
                            </router-link>
                        </template>

                        <div class="mt85 pb120">
                            <template v-if="recomd[2]?.thirdPartyPlatformUrl">
                                <a :href="recomd[2]?.thirdPartyPlatformUrl"
                                    class="cp m-ellipsis disb fs15 lh30 col5a">上一篇：{{
                                        language == 'zh' ? recomd[2].title : recomd[2].titleEn }}</a>
                            </template>
                            <template v-else>
                                <router-link :to="'/NewsInfoView?id=' + recomd[2].articleId"
                                    class="cp m-ellipsis disb fs15 lh30 col5a">上一篇：{{ language == 'zh' ? recomd[2].title
                                        :
                                    recomd[2].titleEn }}</router-link>
                            </template>
                            <template v-if="recomd[3]?.thirdPartyPlatformUrl">
                                <a :href="recomd[3]?.thirdPartyPlatformUrl"
                                    class="cp m-ellipsis disb fs15 lh30 col5a">下一篇：{{
                                        language == 'zh' ? recomd[3].title : recomd[3].titleEn }}</a>
                            </template>
                            <template v-else>
                                <router-link :to="'/NewsInfoView?id=' + recomd[3].articleId"
                                    class="cp m-ellipsis fs15 lh30 disb col5a">下一篇：{{ language == 'zh' ? recomd[3].title
                                        :
                                    recomd[3].titleEn }}</router-link>
                            </template>
                            <router-link to="/NewsView" class="btn cp">{{ language == 'zh' ? '返回列表' : 'Back' }}</router-link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, watch } from "vue"
import { useHead } from '@unhead/vue'
import { useRouter } from 'vue-router'
const router = useRouter()
let banner1 = ref(require('../../assets/icon/news_cover.png'))
let language = ref(localStorage.getItem('language') || 'zh');
let dataTitle = ref('')
let dataContent = ref('')
let keywords = ref('')
let list = ref([])
let recomd = ref([])
const { proxy } = getCurrentInstance();
import moment from 'moment';
onMounted(() => {

    // 使用全局方法
    proxy?.$api.apiBaseInfomation({}).then((data_res) => {
        let res = data_res.data
        if (data_res.code == 200) {
            if (language.value == 'zh') {
                dataTitle = res.siteName
                dataContent = res.description
                keywords = res.keywords
            } else {
                dataTitle = res.siteNameEn
                dataContent = res.descriptionEn
                keywords = res.keywordsEn
            }
            useHead({
                title: dataTitle,
                meta: [
                    { name: 'description', content: dataContent },
                    { property: 'og:title', content: dataTitle },
                    { property: 'og:description', content: dataContent },
                    { name: "keywords", content: keywords }
                ],
            })
        } else {
            proxy?.$message.error(data_res.msg);
        }
    });

});
watch(() =>
    router.currentRoute.value.path,
    (toPath) => {
        if (router.currentRoute.value.query?.id) {
            let id = router.currentRoute.value.query?.id
            proxy?.$api.apiNewsInfo({ id }).then(res => {
                if (res.code == 200) {
                    list.value = res.data
                } else {
                    proxy?.$message.error(res.msg);
                }
            })
        }
        proxy?.$api.apiNewsList({ 'pageSize': 4, 'pageNum': 1, 'isRandom': 1 }).then(res => {
            if (res.code == 200) {
                res.rows.map((item) => {
                    item.updateTime = moment(item.updateTime).format('YYYY-MM-DD')
                })
                recomd.value = res.rows
                console.log('新闻列表', recomd.value);
            } else {
                proxy.$message.error(res.msg);
            }
        })



    }, { immediate: true, deep: true });
</script>
<style lang="less" scoped>
.main {
    border-radius: 10px;
    background: #fff;
    padding: 50px 90px 180px 65px;
}

.pt75 {
    padding-top: 175px;
    padding-bottom: 110px;
}

.ico16 {
    width: 16px;
    height: auto;
}

.pr65 {
    padding-right: 65px;
}

.bb {
    border-bottom: 1px solid #d2d2d2;
}

.mb75 {
    margin-bottom: 75px;
}

.w200 {
    width: 200px;
}

.lh38 {
    line-height: 38px;
}

.w200+.w200 {
    border-left: 1px solid #d2d2d2;
}

.ico20 {
    width: 20px;
    height: auto;
}

.ico19 {
    width: 19px;
    height: auto;
    margin-right: 10px;
    cursor: pointer;
}

.mt80 {
    margin-top: 80px;
}

.recomd_list {
    border-left: 1px solid #d2d2d2;
    padding-left: 70px;
}

.recomd_item {
    margin-top: 40px;
    width: 400px;
    height: 320px;
    position: relative;
    z-index: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 10px;
    display: block;

    .news_recomd_view {
        width: 100%;
        height: 100%;
        color: #fff;
        padding: 40px 45px;

    }

    .moudle {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 10px;
        top: 0;
        left: 0;
    }
}

.mt85 {
    margin-top: 85px;
}

.btn {
    position: relative;
    text-align: center;
    border-radius: 10px;
    width: 140px;
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 16px;
    background: linear-gradient(180deg, #50c8ff, #1e50e6);
    margin-top: 50px;
    display: block;
}

.disb {
    display: block;
}
</style>
