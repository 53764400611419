import { get, post } from "./http";

export const apiGetBanner = (p) => get("/api/siteInfo/indexBanner", p); //首页轮播图
export const apiBaseInfomation = (p) => get("/api/siteInfo/", p); //网站基本信息
export const apiNewsList = (p) => get("/api/article/news/list", p); //新闻列表
export const apiNewsInfo = (p) => get("/api/article/news/" + p.id); //新闻列表

export const apiExampleCategory = (p) => get("/api/case/category", p); //成功案例类别
export const apiExampleCategoryList = (p) => get("/api/case/list", p); //成功案例列表
export const apiExampleCategoryInfo = (p) => get("/api/case/" + p.id); //成功案例详情
export const apiProductCategory = (p) => get("/api/product/category", p); //产品分类
export const apiProductList = (p) => get("/api/product/list", p); //产品列表
export const apiProductInfo = (p) => get("/api/product/" + p.id); //产品详情
export const apiProductBanner = (p) => get("/api/siteInfo/product/banner/list", p); //产品中心banner
export const apiResultCategory = (p) => get("/api/solution/category", p); //解决方案分类
// export const apiResultCategoryInfo = (p) => get("/api/solution/category/detail/" + p.id); //解决方案类别详情
export const apiResulCategorytInfo = (p) => get("/api/solution/category/"+ p.id); //解决方案分类详情
export const apiResultList = (p) => get("/api/solution/listAll", p); //解决方案分类
export const apiResultListChild = (p) => get("/api/solution/listChild", p); //解决方案二级分类
export const apiResultListRecomd = (p) => get("/api/solution/list", p); //解决方案分类
export const apiResultInfo = (p) => get("/api/solution/" + p.id); //解决方案详情
export const apiSingleInfo = (p) => get("/api/article/single/" + p.id); //单页内容
export const apiStaffMessage = (p) => get("/api/article/employeeMessage/list", p); //单页内容
export const submitForm = (p) => post("/server/v1/console/base/data/leave_message/save", p); //自助报修
export const querySubmit = (p) => post("/scheduling/common/data/selleavebytel", p); //查询已提交信息
export const PostConsult = (p) => post("/api/consult/", p); //我要咨询
export const ApiDOwnload = (p) => get("/kbs/info/rar-attach", p); //下载链接
export const ApiDOwnloadList = (p) => get("/api/attachment/category/list", p); //下载中心列表
export const ApiDOwnloadInfo = (p) => get("/api/attachment/list", p); //下载中心文件内容
export const ApiBranchCategory = (p) => get("/api/branch/category", p); //机构分类
export const ApiBranchList = (p) => get("/api/branch/list", p); //机构分类
export const ApiStudyList = (p) => get("/api/article/study/list", p); //学习园地列表
export const ApiStudyCategory = (p) => get("/api/article/study/category", p); //学习园地分类