<template>
  <div class="pagination" :class="isMobile?'mobile':''">
    <!-- <a href="javascript:;" @click="pageTurn(0)" class="first_last" v-if="paging.index > 0">
      <img src="../assets/icon/last.png" class="img_last " alt="">
    </a> -->
    <a href="javascript:;" @click="pageTurn(paging.index - 1)" class="first_last" v-if="paging.index > 0">
      <img src="../assets/icon/last.png" class="img " alt="">
    </a>
    <template v-for="i in sum">
      <a href="javascript:;" @click="pageTurn(i - 1)" :key="i" v-if="showText(i)" v-text="showText(i)"
        :class="i === paging.index + 1 ? 'active' : 'page'">
      </a>
    </template>
    <a href="javascript:;" @click="pageTurn(paging.index + 1)" class="first_last" v-if="paging.index < sum - 1">
      <img src="../assets/icon/next.png" class="img" alt="">
    </a>
    <!-- <a href="javascript:;" @click="pageTurn(sum - 1)" class="first_last" v-if="paging.index < sum - 1">
      <img src="../assets/icon/last.png" class="img_last" alt="">
    </a> -->
  </div>
</template>
<script>
export default {
  props: ["sumCount", "perCount"],
  data() {
    return {
      paging: {
        index: 0, // index记录当前页码
        count: 9, // count表示每页多少条记录
        isMobile: false
      },
    };
  },
  mounted() {
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ?true:false
  },
  computed: {
    sum() {
      // sum表示共有多少页
      return Math.ceil(this.sumCount / this.perCount);
    },
  },
  methods: {
    // 页码显示（有省略号）
    showText(i) {
      const vm = this;
      const index = vm.paging.index;
      const sum = vm.sum;
      if (i < 3 || i > sum - 2) {
        // 前两个和最后两个始终显示
        return i;
      } else if (i <= index + 2 && i >= index) {
        // 当前页的前一页和后一页始终显示
        return i;
      } else if (i === index + 3 || i === index - 1) {
        // 当前页的前前页和后后页显示 ...
        return "...";
      } // 除此之外都不显示
      return false;
    },
    // 翻页
    pageTurn(i) {
      const vm = this;
      if (i < 0 || i >= vm.sum || i === vm.paging.index) {
        return;
      }
      vm.paging.index = i;
      // 告诉父组件翻页了，让父组件自己调用init
      vm.$emit("pageTurn", i, vm.perCount);
    },
  },
};
</script>
<style lang="less" scoped>
.pagination {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .first_last {
  line-height: 45px;

  .img {
    width: 45px;
    height: auto;
    vertical-align: middle;


  }

  .img_last {
    width: 45px;
    height: auto;
    vertical-align: middle;
  }

  margin: 0 20px;
}

.pagination .page {
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  color: #5a5a5a;
  margin: 0 10px;
  font-size: 20px;
}

.pagination a.active {
  width: 30px;
  height: 30px;

  line-height: 28px;
  text-align: center;
  color: #1e50e6;
  margin: 0 10px;
  font-size: 20px;

}

.pagination a.page:hover,
.pagination a.active {
  color: #1e50e6;
  font-size: 20px;
}
.mobile{
  .pagination {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .first_last {
  line-height: 45px;

  .img {
    width: 45px;
    height: auto;
    vertical-align: middle;


  }

  .img_last {
    width: 45px;
    height: auto;
    vertical-align: middle;
  }

  margin: 0 20px;
}

.pagination .page {
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  color: #5a5a5a;
  margin: 0 10px;
  font-size: 28px;
}

.pagination a.active {
  width: 40px;
  height: 40px;

  line-height: 38px;
  text-align: center;
  color: #1e50e6;
  margin: 0 10px;
  font-size: 28px;

}

.pagination a.page:hover,
.pagination a.active {
  color: #1e50e6;
  font-size: 28px;
}
}
</style>
